import * as React from 'react';
import { CommonStyles, getMuiTheme, getModalStyle, getModalClasses } from '../../../hooks/styles';
import { Grid, FormControl, TextField, Button, Modal, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CabinetStyles } from '../../cabinets/cabinet.styles';
import { SelectBox } from '../../../components/selectBox';
import MUIDataTable from 'mui-datatables';
import { uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import dataList from '../../../constants/dataList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SetParModal, { fffSources } from './set-par.modal';
import PoTypeModal from './po-type.modal';
import { TenantsActions, CabinetActions, ProductActions } from '../../../redux/actions';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ProductFormModal from './customer-product-form.modal';
import { getCabinetTypeName } from '../../../hooks/functions';

const ProductSettings: React.FC<any> = (props: any) => {
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const searchStyles = CommonStyles();
	const classes = CabinetStyles();
	const tableTheme = getMuiTheme();
	const { customerId } = useParams() as any;
	const [modalOpen, setModalOpen] = React.useState(false);
	const [productToEdit, setProductToEdit] = React.useState(null as any);
	const [tableData, setTableData] = React.useState([] as any[]);
	const [filterReset, setFilterReset] = React.useState(false);
	const [modalType, setModalType] = React.useState(null as any);
	const [productSource, setproductSource] = React.useState<string>('FFF');
	const [submitPar, setSubmitPar] = React.useState(false);
	const [submitProduct, setSubmitProduct] = React.useState(false);
	const [newProductPayload, setNewProductPayload] = React.useState<any>(null);
	const [newParPayload, setNewParPayload] = React.useState<any>(null);
	const [filter, setFilter] = React.useState({
		product: null,
		poType: null,
	} as any);

	React.useEffect(() => {
		getCustomerCabinetList();
	}, []);

	const getProductFilterList = () => {
		const products = formatTableData(props.products || []).map((product: any) => {
			return { name: product.productName ?? '', itemId: product.productId };
		});
		return products;
	};

	const getPOTypeList = () => {
		const poTypes = props.products.map((product: any) => {
			return { label: product.poType, value: product.poType };
		});
		return uniqBy(poTypes, 'value');
	};

	const resetFilter = () => {
		setFilter({
			product: null,
			poType: null,
		});
		setFilterReset(!filterReset);
	};

	const applyFilter = () => {
		if (!filter.product && !filter.poType) {
			setTableData(formatTableData(props.products || []));
			return;
		}

		const filteredData =
			props.products.filter((product: any) => {
				if (filter.product && filter.product.itemId !== product.productId) {
					return false;
				}
				if (filter.poType && filter.poType !== product.poType) {
					return false;
				}
				return true;
			}) || [];

		setTableData(formatTableData(filteredData));
	};

	const formatTableData = (data: Array<any>): any[] => {
		return data.map((row: any) => {
			return {
				id: row.id,
				productId: row.productId,
				productName: row.productName || '',
				productSource: row.productSource || '',
				cabinetType: row.cabinetType,
				ndc: row.ndc || '',
				packQty: row.packQty || '',
				poType: row.poType,
				salesType: row.salesType,
				gtin: row.gtin || '',
				weight: row.weight ? row.weight + (row.weightUnit || '') : '',
				crl: row.crl,
				minSet: row.minSet,
				maxSet: row.maxSet,
				maxFill: row.maxFill,
				lumQty: row.packToLum ? 'Yes' : 'No',
				active: row.active ? 'Yes' : 'No',
				notes: row.notes,
				sapProducts: row.sapProducts || [],
				defaultSapProduct: row.defaultSapProduct || '',
			};
		});
	};

	const handleModalClose = (refreshData: boolean = false) => {
		setModalOpen(false);
		setSubmitPar(false);
		setSubmitProduct(false);
		if (refreshData) {
			resetFilter();
			props.getCustomerProducts(
				JSON.stringify({
					order: ['cabinetType ASC', 'productId ASC'],
					where: { customerId: customerId },
				}),
			);
		}
	};

	const getProductByProductItemId = (productItemId: string) => {
		return props.products.filter((product: any) => {
			return productItemId === product.productItemId;
		})[0];
	};

	React.useEffect(() => {
		applyFilter();
	}, [props.products, filter]);

	React.useEffect(() => {
		props.getCustomerProducts(
			JSON.stringify({
				order: ['cabinetType ASC', 'productId ASC'],
				where: { customerId: customerId },
			}),
		);
	}, []);

	const ActionsMenu: React.FC<any> = (actionProps: any) => {
		let showParAction = false;
		if (!fffSources.includes(tableData[actionProps.dataIndex].productSource)) {
			showParAction = true;
		}
		if (
			props.authUser.authorizationCheck({
				action: 'create',
				resource: 'products',
				tenantId: 'ALL',
			})
		) {
			showParAction = true;
		}

		if (!showParAction) {
			return <></>;
		}

		return (
			<div>
				<Tooltip title="Edit Product Settings">
					<IconButton
						onClick={() => {
							setModalType('edit');
							setProductToEdit(tableData[actionProps.dataIndex]);
							setproductSource(tableData[actionProps.dataIndex].productSource);
							setModalOpen(true);
						}}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</div>
		);
	};

	const renderActions = (dataIndex: number) => {
		if (
			!props.authUser.authorizationCheck({
				action: 'create',
				resource: 'products',
				tenantId: customerId,
			})
		) {
			return <></>;
		}

		return <ActionsMenu dataIndex={dataIndex} />;
	};

	const getCustomerCabinetList = () => {
		props.getCabinets({
			customerId: customerId,
		});
	};

	React.useEffect(() => {
		if (fffSources.includes(productSource)) {
			newParPayload && props.createCustomerProduct(newParPayload);
			return;
		}
		if (newProductPayload && newParPayload) {
			props.saveNewProduct({
				product: newProductPayload,
				par: newParPayload,
			});
		}
	}, [newProductPayload, newParPayload]);

	return (
		<div>
			{!props.error && (
				<div style={{ margin: '25px 0 10px 0' }} className={searchStyles.searchWrap}>
					<Grid container>
						<Grid item xs={12}>
							<FormControl className={classes.formControl}>
								<Autocomplete
									id="product-select-box"
									options={getProductFilterList()}
									getOptionLabel={(option: any) => {
										return '(' + option.itemId + ') ' + option.name;
									}}
									style={{ width: isDesktopOrLaptop ? 300 : 200 }}
									value={filter.product || null}
									onChange={(event: any, value: any) => setFilter({ ...filter, product: value })}
									renderInput={(params: any) => <TextField {...params} label="Search Products" />}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<SelectBox
									refresh={filterReset}
									style={{ width: 100 }}
									inputLabel={'PO Type'}
									emptyItemLabel={'Choose'}
									listItems={getPOTypeList()}
									onChangeItem={(value: any) => setFilter({ ...filter, poType: value })}
								/>
							</FormControl>
							{!isDesktopOrLaptop && <div></div>}
							<FormControl variant="outlined" className={classes.buttonFormControl}>
								<Button
									onClick={resetFilter}
									type="button"
									className={classes.searchButton}
									variant="contained"
									color="primary"
								>
									Reset
								</Button>
							</FormControl>
						</Grid>
						<FormControl className={classes.buttonFormControl} style={isDesktopOrLaptop ? { marginTop: 14 } : {}}>
							<Button
								variant="contained"
								color="secondary"
								className={classes.viewAllBtn}
								style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
								onClick={() => {
									setModalType('add');
									setProductToEdit(null);
									setModalOpen(true);
									setproductSource('FFF');
								}}
							>
								Add Customer Par Setting
							</Button>
						</FormControl>
					</Grid>
				</div>
			)}
			<MUIDataTable
				data={tableData}
				columns={[
					{
						label: 'Product Id',
						name: 'productId',
					},
					{
						label: 'Product Name',
						name: 'productName',
					},
					{
						label: 'Source',
						name: 'productSource',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'Device Type',
						name: 'cabinetType',
						options: {
							customBodyRender: (value: any) => {
								return getCabinetTypeName(value)
							},
						},
					},
					{
						label: 'NDC',
						name: 'ndc',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'Pack Qty',
						name: 'packQty',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'PO Type',
						name: 'poType',
					},
					{
						label: 'Sales Type',
						name: 'salesType',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'GTIN',
						name: 'gtin',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'Weight (grams)',
						name: 'weight',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'CRL',
						name: 'crl',
						options: {
							hint: `CRL: Clinical Reserve Level- Minimal clinical quantity to care according to provider policy.`,
						},
					},
					{
						label: 'Min Par Set',
						name: 'minSet',
						options: {
							hint: `Min Par Set: Overwrite minimum par level.`,
						},
					},
					{
						label: 'Max Par Set',
						name: 'maxSet',
						options: {
							hint: `Max Par Set: Overwrite maximum par level.`,
						},
					},
					{
						label: 'Max Fill',
						name: 'maxFill',
						options: {
							hint: `Max Fill: Maximum capacity of product per device type`,
						},
					},
					{
						label: 'Pack to LUM',
						name: 'packToLum',
						options: {
							hint: 'Pack to LUM: If the product will be broken down into individual units',
							display: false,
							viewColumns: true,
						},
					},
					{
						label: 'Active',
						name: 'active',
					},
					{
						label: 'Notes',
						name: 'notes',
						options: {
							display: false,
							viewColumns: true,
						},
					},
					{
						label: '',
						name: '',
						options: {
							customBodyRenderLite: renderActions,
							viewColumns: false,
						},
					},
				].map((column: any) => ({
					label: column.label,
					name: column.name,
					options: {
						filter: true,
						display: column.options?.display === false ? false : true,
						change: false,
						viewColumns: column.options?.viewColumns === false ? false : true,
						hint: column.options?.hint || undefined,
						customBodyRender: column.options?.customBodyRender || undefined,
						customBodyRenderLite: column.options?.customBodyRenderLite || undefined,
					},
				}))}
				options={{
					rowsPerPageOptions: dataList.PageLimitOptions,
					filterType: 'dropdown',
					responsive: 'simple',
					filter: false,
					search: false,
					download: true,
					print: true,
					selectableRows: 'none',
				}}
			/>
			<Modal
				disableBackdropClick={true}
				open={modalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div style={modalStyle} className={modalClasses.paper}>
					<h3 id="simple-modal-title">{modalType === 'add' ? 'Add Product' : 'Edit Product'}</h3>
					{modalType === 'add' && (
						<FormControl className={classes.formControl}>
							<SelectBox
								refresh={false}
								selected={productSource}
								style={{ width: 200 }}
								inputLabel="Select Product Source"
								listItems={[
									{ label: 'FFF', value: 'FFF' },
									{ label: 'Self Managed', value: customerId },
								]}
								onChangeItem={value => {
									if (!value) {
										setproductSource('FFF');
										return;
									}
									setproductSource(value);
								}}
							/>
						</FormControl>
					)}
					<>
						{productSource && !fffSources.includes(productSource) && (
							<ProductFormModal
								productSource={productSource}
								getProducts={() =>
									props.getCustomerProducts(
										JSON.stringify({
											order: ['cabinetType ASC', 'productId ASC'],
											where: { customerId: customerId },
										}),
									)
								}
								customerId={customerId}
								product={productToEdit}
								closeModal={handleModalClose}
								submit={submitProduct}
								setProductPayload={(product: any) => {
									setNewProductPayload(product);
								}}
							/>
						)}
						<SetParModal
							customerId={customerId}
							product={productToEdit}
							productSource={modalType === 'add' ? productSource : productToEdit?.productSource}
							closeModal={handleModalClose}
							addProduct={modalType === 'add'}
							submit={submitPar}
							setParPayload={(par: any) => {
								setNewParPayload(par);
							}}
						/>
					</>
					<Grid container>
						<Grid item xs={12}>
							<FormControl className={searchStyles.formControl}>
								<Button
									type="button"
									color="primary"
									variant="contained"
									className={searchStyles.searchButton}
									style={{ marginTop: 12 }}
									onClick={() => {
										if (!fffSources.includes(productSource)) {
											setSubmitProduct(true);
										}
										setSubmitPar(true);

										setTimeout(() => {
											setSubmitProduct(false);
											setSubmitPar(false);
										}, 1000);
									}}
								>
									SAVE
								</Button>
							</FormControl>
							<FormControl className={searchStyles.formControl}>
								<Button
									type="button"
									color="default"
									variant="contained"
									className={searchStyles.cancelBtn}
									style={{ marginTop: 12, marginLeft: 0 }}
									onClick={() => {
										handleModalClose();
									}}
								>
									CANCEL
								</Button>
							</FormControl>
						</Grid>
					</Grid>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	products: state.tenants.products ? state.tenants.products.results : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getCustomerProducts: (fitler: string) => dispatch(TenantsActions.getTenantProducts(fitler)),
	getCabinets: (payload: any) => dispatch(CabinetActions.cabinetsTableRequestStartAction(payload)),
	saveNewProduct: (payload: any) => dispatch(ProductActions.saveNewProduct(payload)),
	createCustomerProduct: (payload: any) => dispatch(TenantsActions.createCustomerProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSettings);
