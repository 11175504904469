import { combineReducers } from 'redux';

// import {
//   cabinetCollectionReducer,
//   CabinetCollectionState,
// } from './cabinet.reducer';

// export interface State {
//   cabinetCollection: CabinetCollectionState;
// }
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import * as reportReducer from './report.reducer';
import * as uiReducer from './ui.reducer';
import * as cabinetReducer from './cabinet.reducer';
import * as tenantsReducer from './tenants.reducer';
import * as invoicesReducer from './invoices.reducer';
import * as inventoryManagementReducer from './inventory-management.reducer';
import * as userReducer from './user.reducer';
import * as userManagementReducer from './user-management.reducer';
import * as alertsReducer from './alerts.reducer';
import * as productReducer from './product.reducer';
import * as ordersReducer from './orders.reducer';
import * as auditReducer from './audit.reducer';

const createRootReducer = (history: History<any>) =>
	combineReducers(
		Object.assign(
			{ router: connectRouter(history) },
			reportReducer,
			uiReducer,
			cabinetReducer,
			tenantsReducer,
			invoicesReducer,
			inventoryManagementReducer,
			userReducer,
			userManagementReducer,
			alertsReducer,
			productReducer,
			ordersReducer,
			auditReducer,
		),
	);
export default createRootReducer;
