export const Constants = {
	DATE_FORMAT: 'YYYY-MM-DD',
	SAVE_SEARCH: {
		PAGE_PREFIX: 'RNI:',
	},
	SEARCH: {
		CUSTOMER_NAME: 'Customer Name',
		CABINET_TYPE: 'Device Type',
		EXPIRY_DATE: 'Expiry Date',
		CABINET: 'Search Devices',
		CABINET_ID: 'Device Id',
		PRODUCT_NAME: 'Product Name',
		PRODUCT_NAME_ID: 'Product Name or ID',
		LOT: 'Lot Number',
		ON_HAND_QTY: 'On Hand',
		INTRANSIT_QTY: 'In Transit',
		INVOICE_DATE: 'Invoice Date',
		CABINET_STATE: 'Device State',
		HISTORY_DATE: 'History Date',
		TRANSMITTAL_DATE: 'Transmittal Date',
		TRANSACTION_DATE_RANGE: 'Transaction Date Range',
		ACTION: 'Action',
		REASON: 'Reason',
		INVOICED: 'Invoiced',
		SETUP_STATUS: 'Setup Status',
		ZIP_CODE: 'Zip Code',
		CREATED_DATE: 'Created Date',
		STATE: 'State',
		FIRST_NAME: 'First Name',
		LAST_NAME: 'Last Name',
		EMAIL: 'Email',
		ALERT_LEVEL: 'Alert Level',
		PAYMENT_STATUS: 'Payment Status',
		SALES_TYPE: 'Sales Type',
		DOSE_TYPE: 'Dose Type',
		SERIAL_NUMBER: 'Serial Number',
		ORDER_NUMBER: 'Order Number',
		ORDER_TYPE: 'Order Type',
		RESOURCE_NAME: 'Resource Name',
		INITIATED_BY: 'Initiated By',
		PAGE_NAME: 'Select Page',
		PRODUCT_OWNERSHIP: 'Owner',
	},
	ALERT: {
		NO_RECORD_FOUND: 'Sorry, no matching records found',
		NOT_AUTHORIZED: 'You are not authorized to view this content',
		SERVER_ERROR: 'A server error has occurred',
		FAILED_TO_GET_CHANNEL: 'Failed to get channel types',
		ERROR_ON_SAVING_CHANNEL: 'An error occurred while saving this channel group',
		FAILED_TO_UPDATE_SAP: 'Failed to update SAP records',
		DELAY: 6000,
	},
	PAGE_TITLE: {
		INVENTORY: 'Current Inventory',
		PAR_UGAGES: 'PAR & Usage',
		INVOICES: 'Invoices',
		ADMIN_INVOICES: 'Administer Invoices',
		CABINETS: 'Device List',
		CABINET_TEMP: 'Device Temperature',
		DEVICE_CONFIG_MANAGEMENT: 'Device Config Management',
		PRODUCTS: 'Product Management',
		CUSTOMERS: 'Customers',
		REPORTS: 'Reports',
		USERS: 'Users',
		TELEMETRY: 'Telemetry',
		INVENTORY_HISTORY: 'Inventory History',
		TRANSACTION_LOG: 'Transaction Log',
		CUSTOMER_DETAILS: 'Customer Details',
		CABINET_DETAILS: 'Device Details',
		UPDATE_INVENTORY: 'Update Inventory',
		PAY_INVOICE: 'Pay Invoice',
		UPDATE_SALES_TYPE: 'Update Sales Type',
		UPDATE_PO_TYPE: 'Update PO Type',
		ORDERS: 'Restock Orders',
		UPDATE_CABINET_NAME: 'Edit Device Info',
		VIEW_PIN: 'View Pin',
		RESTOCK_RECOMMENDATIONS: 'Restock Recommendations',
		ORDER_MANAGEMENT: 'Order Management',
		ADD_CABINET_PRODUCT: 'Add Product',
		CABINET_PRODUCT_INVENTORY: 'Device Inventory',
		SYNC_CUSTOMER: 'Sync Customer',
		EDIT_CUSTOMER: 'Edit Customer',
		INCIDENTS: 'Incidents',
		SUSPENDED_TRANSACTIONS: 'Suspended Transactions',
		SHIPMENTS: 'Shipments',
		PERSONAS: 'Persona Management',
		HELP_VIDEOS: 'Help Videos',
		AUDIT_LOGS: 'Audit Logs',
		SCHEDULED_REPORTS: 'Scheduled Reports',
		SAVE_SEARCH: 'Save Filters',
		SUPPORT: 'User Support',
		CABINET_PROPERTY_SETTINGS: 'Device Property Settings',
		TAGS: 'Serials/Tags',
		ABW_SELECTION: 'ABW Selection',
		CREDIT_CARD_PAYABLE_INVOICES: 'Credit Card-Payable Invoices',
	},
	RESOURCES: [
		'inventory',
		'par',
		'reports',
		'users',
		'alerts',
		'invoices',
		'products',
		'orders',
		'cabinets',
		'tenants',
		'restock',
		'incidents',
		'personas',
		'device_config'
	],
	ROLES: ['OWNER', 'VIEWER'],
	//for api calls to get lists of available help videos. Doesn't need to be secure bc it allows access to public account info only
	VIMEO_APP_AUTH_KEY:
		'basic NDg3MDBhZjQ0NWYxMjNlMTZhMjNhMTJjMTJhOGFmNGRlM2I4YzQ4Yzora25ScExISzZBRXhBSjh4NE5ma0xlYTlvbDRvRVVua1lZMTBFRW5WbW5rcVJqUWc4OXRCaU5MNWp6ZDkvQVZKemtRSjAxRytTdzlOQ3B4VEVsMUJqTE1BSG1URFNOR0Jha3lIQWJsb2FMUjBaNjd6U3N4V1UrY055aTFKSG1CeQ==',
};

export const List = {
	ScheduleReportFrequency: [
		'NEVER_SCHEDULE',
		'DAILY_RNI_SAVE_SEARCH_REPORT',
		'WEEKLY_RNI_SAVE_SEARCH_REPORT',
		'MONTHLY_RNI_SAVE_SEARCH_REPORT',
	],
	ScheduleEnablePages: [
		'RNI:INVENTORY_HISTORY',
		'RNI:TRANSACTION_LOG',
		'RNI:DISPENSED_PRODUCTS',
		'RNI:WAREHOUSE_SHIPMENTS',
		'RNI:CABINET_TEMPERATURE',
	],
};
