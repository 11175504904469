import * as React from 'react';
import { DashboardStyles, CommonStyles } from '../../hooks/styles';
import {
	Container,
	Paper,
	Button,
	Tab,
	Tabs,
	TextField,
	FormControl,
	Grid,
	Modal,
	AppBar,
	Tooltip,
	Box,
	IconButton,
} from '@material-ui/core';
import { uniqBy } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import BasePage from '../common/base';
import Loading from '../../components/loading';
import { CabinetActions, AlertActions, UserActions } from '../../redux/actions';
import { GetUserTimezone, UserDateFormat, valueMapper } from '../../hooks/functions';
import { CabinetStyles } from './cabinet.styles';
import CabinetsChartComponent from './cabinet-chart.component';
import CabinetConfiguration from './cabinet-configuration.component';
import CabinetProviders from './cabinet-providers.component';
import CabinetLocationTracking from './cabinet-location-tracking.component';
import CabinetUsers from './cabinet-users.component';
import CabinetProperties from './cabinet-properties.component';
import CabinetInventory from './cabinet-inventory';
import PendingShipments from './pending-shipments.component';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Constants } from '../../constants/Constants';
import ClearIcon from '@material-ui/icons/Clear';
import { DesktopDateRangePopup, MobileDateRangePopup } from '../../components/dateRangePopup';
import { useMediaQuery } from 'react-responsive';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { addDays } from 'date-fns';
import { ConvertDateTimeForRequest } from '../../hooks/functions/ConvertDateTimeForRequest';
import FlexDetailsComponent from './flex-details.component';
import CabinetCommandsComponent from './cabinet-commands.component';
import DeviceTags from './device-tags.component';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { snakeCase } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import CabinetsConnectivityComponent from './cabinet-connectivity.component';

const moment = extendMoment(Moment as any);

enum DetailTabs {
	Config = 'Config',
	Graph = 'Graph',
	Products = 'Products',
	Users = 'Users',
	Inventory = 'Inventory',
	Shipments = 'Shipments',
	Providers = 'Providers',
	Commands = 'Commands',
	Connectivity = 'Connectivity'
}

const CabinetsDetailsComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const firstRender = React.useRef(true);
	const searchStyles = CommonStyles();
	const styles = DashboardStyles();
	const classes = CabinetStyles();
	const { cabinetId, customerId } = useParams<any>();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [dateRange, setDateRange] = React.useState('');
	const [dates, setDates] = React.useState<any>(null);
	const [editMode, setEditMode] = React.useState(false);

	const [chartDateFormat, setChartDateFormat] = React.useState<any>(null);
	const [tableData, setTableData] = React.useState([] as any[]);
	const [filterReset, setFilterReset] = React.useState(false);
	const [filter, setFilter] = React.useState({
		product: null,
		holder: null,
		dispenser: null,
		capacity: null,
	} as any);
	const [connectivityPayload, setConnectivityPayload] = React.useState<any>({});
	const [detailTab, setDetailTab] = React.useState<any>(DetailTabs.Inventory);
	const [openReplaceMachineDialog, setOpenReplacedMachineDialog] = React.useState(false);
	const [submittingMachineReplacement, setSubmittingMachineReplacement] = React.useState(false);
	const [machineReplacementApiError, setMachineReplacementApiError] = React.useState('');
	const [editedCabinetPropertiesForMachineReplacement, setEditedCabinetPropertiesForMachineReplacement] =
		React.useState({ machineId: '' });

	const handleTabChange = (event: any, newValue: 'Grid' | 'Graph') => {
		if (props.isLoading) {
			return;
		}
		if (newValue === detailTab) {
			return;
		}
		setDetailTab(newValue as DetailTabs);
	};

	React.useEffect(() => {
		if (firstRender.current && props.selectedCabinet) {
			setDetailTab(DetailTabs.Inventory);
			firstRender.current = false;
			if (['EM', 'MinibarRx', 'RFID', 'VIPc', 'WEIGHT'].includes(props.selectedCabinet.cabinetType)) {
				const endDate = addDays(new Date().setHours(23, 59, 59, 0), 0);
				const startDate = addDays(new Date().setHours(0, 0, 0, 0), -7);
				const selectedDatesRange = {
					startDate: new Date(startDate),
					endDate: addDays(new Date().setHours(23, 59, 59, 0), 0),
					key: 'selection',
				};
				setDates(selectedDatesRange);
				setDateRange(
					UserDateFormat(props.properties, new Date(startDate), false) +
						' - ' +
						UserDateFormat(props.properties, new Date(endDate), false),
				);
				// const payload: any = `{"order":["tempTimestamp asc"],"where":{"customerId":"${customerId}", "and":[{"deviceId":"${cabinetId}"},{"tempTimestamp":{"gt":"${ConvertDateTimeForRequest(
				// 	props.properties,
				// 	startDate,
				// )}"}},{"tempTimestamp":{"lt":"${ConvertDateTimeForRequest(props.properties, endDate)}"}}]}}`;
				const payload: any = [
					{key: 'customerId', value: customerId},
					{key: 'deviceId', value: cabinetId},
					{key: 'startDate', value: ConvertDateTimeForRequest(props.properties, startDate)},
					{key: 'endDate', value: ConvertDateTimeForRequest(props.properties, endDate)},
					{key: 'sortOrder', value: 'asc'},
				]
				const alertsPayload: any = `{"include":[{"relation":"sourceType"}],"where":{"customerId":"${customerId}", "and":[{"deviceId":"${cabinetId}"},{"alertTimestamp":{"gt":"${ConvertDateTimeForRequest(
					props.properties,
					startDate,
				)}"}},{"alertTimestamp":{"lt":"${ConvertDateTimeForRequest(
					props.properties,
					endDate,
				)}"}}]},"order":["alertTimestamp asc"]}`;
				setDetailTab(DetailTabs.Graph);
				setConnectivityPayload({deviceId: cabinetId, startTime: ConvertDateTimeForRequest(props.properties, startDate), endTime: ConvertDateTimeForRequest(props.properties, endDate)})
				props.getTelemetryMetrics({ metricsFilter: payload, alertFilter: alertsPayload });
			}
			props.resetCabinetTypeProperties();
		}
	}, [props.selectedCabinet]);

	React.useEffect(() => {
		if (props.authUser && cabinetId) {
			props.getCabinetDetails({
				cabinetId: cabinetId,
				customerId: customerId,
				setCabinet: props.selectedCabinet ? false : true,
			});
		}
	}, [props.authUser]);

	const getProductFilterList = () => {
		if (!props.cabinetDetails) {
			return [];
		}
		const products = props.cabinetDetails.map((details: any) => {
			return { name: details.name, itemId: details.itemId };
		});
		return uniqBy(products, 'itemId');
	};

	const getCapacityFilterList = () => {
		if (!props.cabinetDetails) {
			return [];
		}
		const capacities = props.cabinetDetails.map((details: any) => {
			return { label: details.capacity, value: details.capacity };
		});
		return uniqBy(capacities, 'value');
	};

	const getDispenserList = () => {
		let dispensers: any[] = [];
		for (let i = 0; i < 14; i++) {
			dispensers.push({ label: i, value: i.toString() });
		}
		return dispensers;
	};

	const applyFilter = () => {
		if (!filter.product && !filter.holder && !filter.dispenser) {
			setTableData(props.cabinetDetails || null);
			return;
		}

		const filteredData =
			props.cabinetDetails.filter((details: any) => {
				if (filter.product && filter.product.itemId !== details.itemId) {
					return false;
				}
				if (filter.holder && filter.holder !== details.holder) {
					return false;
				}
				if (filter.dispenser && filter.dispenser !== details.dispenser) {
					return false;
				}
				if (filter.capacity && filter.capacity !== details.capacity) {
					return false;
				}
				return true;
			}) || null;

		setTableData(filteredData);
	};

	const resetFilter = () => {
		setFilter({ product: null, holder: null, dispenser: null, capacity: null });
		setFilterReset(!filterReset);
	};

	const showLoadingMsg = () => !props.authUser;

	const showCalendar = () => {
		setOpen(true);
	};

	const hideCalendar = () => {
		//setOpen(false);
	};

	const onSelect = (dates: any) => {
		setDates(dates);
		const startDateString = dates.startDate ? UserDateFormat(props.properties, dates.startDate, false) : '';
		const endDateString = dates.endDate ? UserDateFormat(props.properties, dates.endDate, false) : '';
		setDateRange(startDateString + ' - ' + endDateString);
	};

	const handleSearch = () => {
		if (!dateRange) {
			return;
		}
		const payloadDates = {
			startDate: ConvertDateTimeForRequest(props.properties, dates.startDate),
			endDate: ConvertDateTimeForRequest(props.properties, dates.endDate),
		};
		// const tempPayload: any = `{"order":["tempTimestamp asc"],"where":{"customerId":"${customerId}", "and":[{"deviceId":"${cabinetId}"},{"tempTimestamp":{"gt":"${payloadDates.startDate}"}},{"tempTimestamp":{"lt":"${payloadDates.endDate}"}}]}}`;
		const payload: any = [
			{key: 'customerId', value: customerId},
			{key: 'deviceId', value: cabinetId},
			{key: 'startDate', value: ConvertDateTimeForRequest(props.properties, payloadDates.startDate)},
			{key: 'endDate', value: ConvertDateTimeForRequest(props.properties, payloadDates.endDate)},
			{key: 'sortOrder', value: 'asc'},
		]
		const alertsPayload: any = `{"include":[{"relation":"sourceType"}],"where":{"customerId":"${customerId}", "and":[{"deviceId":"${cabinetId}"},{"alertTimestamp":{"gt":"${payloadDates.startDate}"}},{"alertTimestamp":{"lt":"${payloadDates.endDate}"}}]},"order":["alertTimestamp asc"]}`;
		props.getTelemetryMetrics({ metricsFilter: payload, alertFilter: alertsPayload });
		setConnectivityPayload({
			deviceId: cabinetId,
			startTime: payloadDates.startDate,
			endTime: payloadDates.endDate
		})
	};

	const exportTemperatureCsv = () => {
		const filter = {
			customerId: customerId,
			deviceId: cabinetId,
			timezone: GetUserTimezone(props.properties),
			startTime: ConvertDateTimeForRequest(props.properties, moment(dates.startDate).format()),
			endTime: ConvertDateTimeForRequest(props.properties, moment(dates.endDate.setHours(23, 59, 59)).format()),
		};

		const temperatureParams = {
			filter_object_from_ui: filter,
			report_type: 'RNI_UIEXPORT_CabinetTemperatureReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
		};

		props.sendOneTimeReportRequest(temperatureParams);
	};

	const getChartDateFormat = () => {
		if (!dates) {
			return '{value:%e-%b}';
		}
		const momentRange = moment().range(dates.startDate, dates.endDate);
		const daysInRange = momentRange.end.diff(dates.startDate, 'days');
		if (daysInRange < 7) {
			return '{value:%e-%b %H:%M}';
		}
		return '{value:%e-%b}';
	};

	const closeEditMode = (reset: boolean = false, updatedCustomerId?: string) => {
		setEditMode(false);
		if (reset) {
			props.getCabinetDetails({ cabinetId: cabinetId, customerId: updatedCustomerId || customerId, setCabinet: true });
		}
	};

	const getDetailValue = (value: any, map: string) => {
		const mappedValue = valueMapper(value, map);
		if (mappedValue) {
			return mappedValue;
		}
		return value || '';
	};

	const hasConfigTab = () => {
		if (['EM', 'WEIGHT'].includes(props.selectedCabinet.cabinetType)) {
			return true;
		}
		if (
			props.selectedCabinet.cabinetType === 'Virtual' &&
			((props.selectedCabinet.cabinetProperties.providerTracking && props.selectedCabinet.cabinetProperties.providerTracking !== 'No') ||
			(props.selectedCabinet.cabinetProperties.locationTracking))
		) {
			return true;
		}
		// ['RFID', 'VISION'] no config tab
		return false;
	};

	const hasCommandTab = () => {
		if (props.selectedCabinet.cabinetType === 'WEIGHT') {
			return true;
		}
		return false;
	};

	const hasConnectivityTab = () => {
		if (props.authUser.record.claims['ALL'] && props.selectedCabinet.cabinetType === 'WEIGHT') {
			return true;
		}
		return false;
	}

	const getChartFilter = () => (
		<div className={searchStyles.searchWrap} style={{ position: 'relative' }}>
										<Grid container>
											{props.selectedCabinet &&
												['EM', 'MinibarRx', 'RFID', 'VIPc', 'WEIGHT'].includes(props.selectedCabinet.cabinetType) && (
													<Grid item xs={12}>
														<FormControl className={classes.formControl}>
															<TextField
																autoComplete="off"
																id="date_range"
																label={'Date Range'}
																onFocus={showCalendar}
																onBlur={hideCalendar}
																value={dateRange}
																style={{ width: '200px' }}
																error={!dateRange}
																helperText={!dateRange ? 'A date range is mandatory' : ''}
																InputProps={{
																	value: dateRange,
																	endAdornment: dateRange && (
																		<ClearIcon
																			style={{ cursor: 'pointer' }}
																			onClick={() => {
																				setDateRange('');
																				setDates({
																					startDate: new Date(),
																					endDate: new Date(),
																					key: 'selection',
																				});
																			}}
																		/>
																	),
																}}
															/>
														</FormControl>
														<FormControl className={classes.formControl} style={{ verticalAlign: 'bottom' }}>
															<Button
																style={{ width: 100 }}
																onClick={() => handleSearch()}
																type="button"
																className={classes.searchButton}
																variant="contained"
																color="primary"
															>
																Search
															</Button>
														</FormControl>
														{detailTab === 'Graph' && <FormControl className={classes.formControl} style={{ verticalAlign: 'bottom' }}>
															<div>
																<Button
																	id="export_button"
																	style={{ width: 100 }}
																	onClick={() => exportTemperatureCsv()}
																	type="button"
																	className={classes.searchButton}
																	variant="contained"
																	color="primary"
																>
																	Export
																</Button>
															</div>
														</FormControl>}
													</Grid>
												)}
										</Grid>
										{open && isDesktopOrLaptop && (
											<DesktopDateRangePopup setOpen={setOpen} onSelect={onSelect} dates={dates} />
										)}
									</div>
	)

	const getCabinetConfig = () => {
		if (['EM', 'MinibarRx'].includes(props.selectedCabinet.cabinetType)) {
			return (
				<>
					<h3>Product Configuration</h3>
					<CabinetConfiguration
						getProductFilterList={getProductFilterList}
						filter={filter}
						setFilter={setFilter}
						filterReset={filterReset}
						getDispenserList={getDispenserList}
						getCapacityFilterList={getCapacityFilterList}
						resetFilter={resetFilter}
						tableData={tableData}
						error={props.error}
					/>
					<h3>Cabinet User Configuration</h3>
					<CabinetUsers cabinet={props.selectedCabinet} />
				</>
			);
		}
		if (props.selectedCabinet.cabinetType === 'Virtual') {
			return (
				<>
					{props.selectedCabinet.cabinetProperties.providerTracking && props.selectedCabinet.cabinetProperties.providerTracking !== 'No' && <>
					<h3>Provider Configuration</h3>
					<CabinetProviders
						authUser={props.authUser}
						selectedCustomer={props.selectedCustomer}
						cabinet={props.selectedCabinet}
						hideHeader={true}
					/>
					</>}
					{props.selectedCabinet.cabinetProperties.locationTracking && <>
						<h3>Location Tracking Configuration</h3>
						<CabinetLocationTracking
							authUser={props.authUser}
							selectedCustomer={props.selectedCustomer}
							cabinet={props.selectedCabinet}
							hideHeader={true}
						/>
					</>}
				</>
			);
		}
		if (props.selectedCabinet.cabinetType === 'WEIGHT') {
			return (
				<>
					<h3>Flex Configuration</h3>
					<FlexDetailsComponent cabinetId={props.selectedCabinet.cabinetId} />
				</>
			);
		}
	};

	const getCabinetCommands = () => {
		return (
			<>
				<CabinetCommandsComponent />
			</>
		);
	};

	const handleOpenReplaceMachineDialog = () => {
		setOpenReplacedMachineDialog(true);
	};
	const handleCloseReplaceMachineDialog = () => {
		setOpenReplacedMachineDialog(false);
	};
	const handleReplacingMachine = () => {
		console.log('Handle the Replace Button being clicked!');

		setSubmittingMachineReplacement(true);

		let cabinetData: any = { ...props.selectedCabinet };
		console.log('cabinet data before edits: ', cabinetData);
		if (cabinetData.properties && !cabinetData.properties.host_name) {
			cabinetData.properties.host_name = cabinetData.properties.enclosure_serial_no;
		}
		for (const key in cabinetData) {
			if (cabinetData[key] === null) {
				delete cabinetData[key];
			}
		}

		const allProperties = { ...cabinetData.cabinetProperties, ...editedCabinetPropertiesForMachineReplacement };

		delete cabinetData.cabinetProperties;
		const parsedProperties: any = {};
		Object.keys(allProperties).forEach((propertyKey: string) => {
			const parsedPropertyKey = snakeCase(propertyKey);
			const propertyValue = props.cabinetTypeProperties.find((typeProp: any) => typeProp.property_key === propertyKey)
				? allProperties[propertyKey]
				: null;
			parsedProperties[parsedPropertyKey] = propertyValue;
		});
		if (parsedProperties['consignment'] && parsedProperties['consignment'] === true) {
			parsedProperties['consignment'] = 1;
		}
		cabinetData.properties = parsedProperties;
		console.log('Cabinet Data Payload: ', cabinetData);
		const payload: any = {
			data: cabinetData,
			success: () => {
				setSubmittingMachineReplacement(false);
				handleCloseReplaceMachineDialog();
			},
			error: () => {
				setSubmittingMachineReplacement(false);
				setMachineReplacementApiError('Failed to replace machine ' + getCabinetMachineId() + '.  Check Network.');
			},
		};
		props.saveCabinetProperties(payload);
	};
	// Cabinets have many identifiers and may not have a machine ID, therefore we'll show another ID
	const getCabinetMachineId = () => {
		if (!props.selectedCabinet) {
			console.error('getCabinetMachineId called with no "selectedCabinet".');
			return 'some cabinet';
		}
		if (props.selectedCabinet.cabinetProperties && props.selectedCabinet.cabinetProperties.machineId) {
			return props.selectedCabinet.cabinetProperties.machineId;
		}
		if (props.selectedCabinet.cabinetProperties && props.selectedCabinet.cabinetProperties.enclosureSerialNo) {
			return props.selectedCabinet.cabinetProperties.enclosureSerialNo;
		}
		if (props.selectedCabinet.cabinetName) {
			return props.selectedCabinet.cabinetName;
		}
		return props.selectedCabinet.cabinetId;
	};
	const handleNewMachineIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedCabinetPropertiesForMachineReplacement({ machineId: event.target.value });
	};

	React.useEffect(() => {
		setChartDateFormat(getChartDateFormat());
	}, [props.cabinetTemperature]);

	React.useEffect(() => {
		applyFilter();
	}, [props.cabinetDetails, filter]);

	return (
		<BasePage title={props.activeMenu?.title || 'Cabinet Details'}>
			<div className={styles.appBarSpacer}></div>

			{/* even on a page with no filters, use this component to parse info from the url, but hide its UI */}
			<GlobalFiltersComponentContainer pageName="CABINET_DETAILS" executeSearch={() => {}} uiHidden={true} />

			<Container maxWidth={false} className={styles.container}>
				{showLoadingMsg() ? (
					<Loading message="" />
				) : (
					<div>
						<div className={styles.appBarSpacer}>
							<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
								<Button
									className={classes.backButton}
									onClick={() => {
										history.push(`/cabinet-list/${customerId}`);
										props.setBackFromDetails(true);
									}}
									variant="contained"
									color="primary"
								>
									Back
								</Button>
								{props.selectedCabinet && (
									<h3 className={classes.cabinetDetailsHeading}>
										{props.selectedCabinet.cabinetName
											? `${props.selectedCabinet.cabinetName}(${props.selectedCabinet.cabinetId})`
											: `(${props.selectedCabinet.cabinetId})`}
									</h3>
								)}
							</Box>
						</div>
						<div>
							{props.selectedCabinet && (
								<Paper className={classes.paper} style={{ marginBottom: '25px' }}>
									<div>
										<h3>Device Properties</h3>
										<Grid container>
											<Grid item xs={6} sm={3}>
												<div className={styles.detailTitle}>Device Type</div>
												<div className={styles.detailValue}>
													{getDetailValue(props.selectedCabinet.cabinetType, 'cabinetTypes')}
												</div>
											</Grid>
											{!editMode && (
												<Grid item xs={6} sm={3}>
													<div className={styles.detailTitle}>Customer Id</div>
													<div className={styles.detailValue}>{props.selectedCabinet.customerId || ''}</div>
												</Grid>
											)}
											{!editMode && (
												<Grid item xs={6} sm={3}>
													<div className={styles.detailTitle}>Status</div>
													<div className={styles.detailValue}>
														{getDetailValue(props.selectedCabinet.cabinetState, 'cabinetStates')}
													</div>
												</Grid>
											)}
											{!editMode && (
												<Grid item xs={6} sm={3}>
													<div className={styles.detailTitle}>Temp Control</div>
													<div className={styles.detailValue}>{props.selectedCabinet.tempControl}</div>
												</Grid>
											)}
											<CabinetProperties
												authUser={props.authUser}
												selectedCustomer={props.selectedCustomer}
												cabinet={props.selectedCabinet}
												editMode={editMode}
												closeEditMode={closeEditMode}
												isLoading={props.isLoading}
												key={`${props.selectedCabinet.cabinetName}_cabpropscomp`}
											/>
										</Grid>
									</div>
									{
										//only cabinets owners can edit these props
										!editMode &&
											props.cabinetTypeProperties &&
											props.authUser?.authorizationCheck({
												action: 'create',
												resource: 'cabinets',
												tenantId: customerId ?? 'ALL',
											}) && (
												<Grid container>
													<Grid item xs={3}>
														<FormControl>
															<Button
																onClick={() => {
																	setEditMode(true);
																}}
																type="button"
																className={classes.searchButton}
																variant="contained"
																color="primary"
															>
																EDIT
															</Button>
														</FormControl>
													</Grid>
													<Grid item xs={9}>
														<FormControl>
															<Tooltip title="Replace this machine with a new device" placement="top">
																<Button
																	onClick={handleOpenReplaceMachineDialog}
																	type="button"
																	className={classes.searchButton}
																	variant="contained"
																	color="primary"
																>
																	REPLACE
																</Button>
															</Tooltip>
														</FormControl>
													</Grid>
													<Dialog open={openReplaceMachineDialog} onClose={handleCloseReplaceMachineDialog}>
														<DialogTitle>Replace Machine {getCabinetMachineId()}</DialogTitle>
														{!submittingMachineReplacement && (
															<>
																<DialogContent>
																	Replacing the machine means the old device ({getCabinetMachineId()}) is to be removed
																	from the system. Order history will be preserved, but the serial number will change to
																	the new device. The hostname will remain the same. This action can <b>not</b> be
																	undone and if you want to re-use the old device you'll need to run the replacement
																	process again.
																	<br />
																	<br />
																	Are you sure you want to replace this machine ({getCabinetMachineId()})?
																	<br />
																	<br />
																	<TextField
																		label="Enter the new machine's serial number"
																		value={editedCabinetPropertiesForMachineReplacement.machineId}
																		onChange={handleNewMachineIdChanged}
																		variant="outlined"
																		fullWidth
																	/>
																	{machineReplacementApiError && (
																		<>
																			<br />
																			<br />
																			<p style={{ color: 'red' }}>{machineReplacementApiError}</p>
																		</>
																	)}
																</DialogContent>
																<DialogActions>
																	<Button onClick={handleCloseReplaceMachineDialog}>Cancel</Button>
																	<Button
																		onClick={handleReplacingMachine}
																		disabled={editedCabinetPropertiesForMachineReplacement.machineId === ''}
																	>
																		Replace Machine
																	</Button>
																</DialogActions>
															</>
														)}
														{submittingMachineReplacement && (
															<>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		height: '100vh',
																	}}
																>
																	<CircularProgress />
																</div>
															</>
														)}
													</Dialog>
												</Grid>
											)
									}
								</Paper>
							)}
							{props.selectedCabinet &&
								props.selectedCabinet.cabinetType === 'WEIGHT' &&
								props.authUser.record.claims['ALL'] && (
									<DeviceTags selectedCabinet={props.selectedCabinet} authUser={props.authUser} />
								)}
							{props.selectedCabinet && (
								<AppBar position="static" color="default">
									<Tabs
										variant="scrollable"
										scrollButtons="on"
										aria-label="scrollable tabs"
										value={detailTab}
										indicatorColor="primary"
										textColor="primary"
										onChange={handleTabChange}
									>
										{['EM', 'MinibarRx', 'RFID', 'VIPc', 'WEIGHT'].includes(props.selectedCabinet.cabinetType) && (
											<Tab
												className={isDesktopOrLaptop ? '' : classes.detailsTab}
												value="Graph"
												label="Temperature Graph"
											/>
										)}
										{props.authUser?.authorizationCheck({
											action: 'read',
											resource: 'inventory',
											tenantId: props.selectedCabinet.customerId,
										}) && (
											<Tab
												className={isDesktopOrLaptop ? '' : classes.detailsTab}
												value="Inventory"
												label="Inventory"
											/>
										)}
										{props.authUser?.authorizationCheck({
											action: 'read',
											resource: 'orders',
											tenantId: props.selectedCabinet.customerId,
										}) && (
											<Tab
												className={isDesktopOrLaptop ? '' : classes.detailsTab}
												value="Shipments"
												label="Shipments"
											/>
										)}
										{hasConfigTab() && (
											<Tab
												className={isDesktopOrLaptop ? '' : classes.detailsTab}
												value="Config"
												label="Configuration"
											/>
										)}
										{hasCommandTab() && (
											<Tab className={isDesktopOrLaptop ? '' : classes.detailsTab} value="Commands" label="Commands" />
										)}
										{
											hasConnectivityTab() && (
												<Tab className={isDesktopOrLaptop ? '' : classes.detailsTab} value="Connectivity" label="Connectivity" />
											)
										}
									</Tabs>
								</AppBar>
							)}

							{detailTab === DetailTabs.Graph && (
								<div>
									{getChartFilter()}
									<CabinetsChartComponent
										data={{
											temp: props.cabinetTemperature,
											alerts: props.alertsList,
											cabinetType: props.selectedCabinet.cabinetType,
										}}
										dateFormat={chartDateFormat}
										timezone={GetUserTimezone(props.properties)}
									/>
								</div>
							)}
							{detailTab === DetailTabs.Config && <>{getCabinetConfig()}</>}
							{detailTab === DetailTabs.Commands && <>{getCabinetCommands()}</>}
							{detailTab === DetailTabs.Inventory && (
								<CabinetInventory
									authUser={props.authUser}
									selectedCustomer={props.selectedCustomer}
									cabinet={props.selectedCabinet}
								/>
							)}
							{detailTab === DetailTabs.Shipments && (
								<PendingShipments
									authUser={props.authUser}
									selectedCustomer={props.selectedCustomer}
									cabinet={props.selectedCabinet}
								/>
							)}
						</div>
						{detailTab === DetailTabs.Connectivity && (
							<>
								{getChartFilter()}
								<CabinetsConnectivityComponent
								data={{
									temp: props.cabinetTemperature,
									alerts: props.alertsList,
									cabinetType: props.selectedCabinet.cabinetType,
								}}
								connectivityPayload={connectivityPayload}
								cabinetId={cabinetId}
								dates={dates}
								properties={props.properties}
								dateFormat={chartDateFormat}
								timezone={GetUserTimezone(props.properties)}
								exportTemperatureCsv={exportTemperatureCsv}
								/>
							</>
						)}
						{open && !isDesktopOrLaptop && <MobileDateRangePopup setOpen={setOpen} onSelect={onSelect} dates={dates} />}
					</div>
				)}
			</Container>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCustomer: state.ui.selectedCustomer,
	selectedCabinet: state.cabinet.selectedCabinet,
	cabinetDetails: state.cabinet.cabinetDetails,
	authUser: state.user.authUser,
	error: state.cabinet.detailsError,
	alertsList: state.alerts.alertsList ? state.alerts.alertsList.data.result : [],
	cabinetTemperature: state.cabinet && state.cabinet.telemetryMetrics ? state.cabinet.telemetryMetrics.temperature : [],
	telemetryMetrics: state.cabinet && state.cabinet.telemetryMetrics ? state.cabinet.telemetryMetrics : {temperature: [], connectivity: []},
	cabinetProducts: state.cabinet.products || [],
	isLoading: state.ui.isLoading,
	activeMenu: state.ui.menuState,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	selectedDateRange: state.ui.selectedDateRange,
	cabinetTypeProperties: state.cabinet.cabinetTypePropertyList,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetDetails: (payload: { cabinetId: string; setCabinet: boolean }) =>
		dispatch(CabinetActions.getCabinetDetailsRequest(payload)),
	getAlerts: (filter: any) => dispatch(AlertActions.alertsRequestStartAction({ filter, showError: false })),
	getTemperature: (filter: any) => dispatch(CabinetActions.getTemperatureAction(filter)),
	getTelemetryMetrics: (filter: any) => dispatch(CabinetActions.getTelemetryMetrics(filter)),
	getTemperatureCsv: (payload: {
		customerId: string;
		deviceId: number;
		timezone: string;
		startTime: string;
		endTime: string;
	}) => dispatch(CabinetActions.getTemperatureCsvAction(payload)),
	resetCabinetTypeProperties: () => dispatch(CabinetActions.setPropertiesByCabinetType(null)),
	setSelectedCabinet: (cabinetId: string) => dispatch(CabinetActions.setCabinet(cabinetId)),
	setBackFromDetails: (backFromDetails: boolean) => dispatch(CabinetActions.setBackFromDetails(backFromDetails)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	saveCabinetProperties: (payload: any) => dispatch(CabinetActions.saveCabinetProperties(payload)),
	getConnectivityData: (filter: any) => dispatch(CabinetActions.getCabinetConnectivityGraph(filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetsDetailsComponent);