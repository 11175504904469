import React from 'react';
import { List, ListItem, ListItemText, DialogTitle, Dialog, ListItemIcon } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { PracticePageStyles } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { UIAction } from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import { useMediaQuery } from 'react-responsive';

interface LinkableActionsProps {
	open: boolean;
	onClose: any;
	links: any;
	onCallBack?: (payload: any[]) => void;
	userNav: any;
}

const LinkableActions = (props: LinkableActionsProps) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const dispatch = useDispatch();
	const classes = PracticePageStyles();
	const history = useHistory();
	const onCallBack = (data: any) => {
		props.onCallBack && props.onCallBack(data);
	};
	const actionItems = [
		{ keyword: 'inventory', title: Constants.PAGE_TITLE.UPDATE_INVENTORY },
		{ keyword: 'inventory', title: Constants.PAGE_TITLE.INVENTORY },
		{ keyword: 'reports', title: Constants.PAGE_TITLE.TRANSACTION_LOG },
		{ keyword: 'reports', title: Constants.PAGE_TITLE.INVENTORY_HISTORY },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.CABINETS },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.CABINET_DETAILS },
		{ keyword: 'tenants', title: Constants.PAGE_TITLE.CUSTOMERS },
		{ keyword: 'tenants', title: Constants.PAGE_TITLE.CUSTOMER_DETAILS },
		{ keyword: 'tenants', title: Constants.PAGE_TITLE.SYNC_CUSTOMER },
		{ keyword: 'tenants', title: Constants.PAGE_TITLE.EDIT_CUSTOMER },
		{ keyword: 'invoices', title: Constants.PAGE_TITLE.INVOICES },
		{ keyword: 'invoices', title: Constants.PAGE_TITLE.PAY_INVOICE },
		{ keyword: 'products', title: Constants.PAGE_TITLE.UPDATE_SALES_TYPE },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.UPDATE_PO_TYPE },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.CABINET_PRODUCT_INVENTORY },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.UPDATE_CABINET_NAME },
		{ keyword: 'cabinets', title: Constants.PAGE_TITLE.VIEW_PIN },
		{ keyword: 'cabinets', title: 'Regenerate Pin' },
		{ keyword: 'cabinets', title: 'Enable User' },
		{ keyword: 'cabinets', title: 'Disable User' },
	];
	const getKeywordFromTitle = (title: string) => {
		console.log('title', title);
		const index = actionItems.findIndex((obj: { title: string }) => obj.title === title);
		return actionItems[index] && actionItems[index].keyword ? actionItems[index].keyword : title;
	};

	/*
	 *  When applicable, navigate to a new page
	 *   We have set filters as a JSON object in the querystring
	 * the GlobalFilters component on the new page interprets this to update state and load data
	 */
	const fireNavStateChange = (title: string, url: string) => {
		dispatch(
			UIAction.setNavigationState({
				title: title,
				url: url,
			}),
		);
	};

	return (
		<Dialog aria-labelledby="action-dialog-title" open={props.open} onClose={props.onClose}>
			<DialogTitle id="simple-dialog-title">Actions</DialogTitle>
			<List
				component="nav"
				aria-label="secondary mailbox folders"
				className={isDesktopOrLaptop ? classes.actionDialogList : classes.actionDialogListMobile}
			>
				{props.links &&
					props.links.map((item: any, key: number) => {
						if (props.userNav.includes(getKeywordFromTitle(item.title))) {
							return (
								<ListItem
									button
									onClick={() => {
										if (item.callback) {
											onCallBack(item.callback);
										}
										if (item.url) {
											history.push(item.url);
											fireNavStateChange(item.title, item.url);
										}
									}}
									key={key}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.title} secondary={item.suffix} />
								</ListItem>
							);
						}
					})}
			</List>
		</Dialog>
	);
};

//export default LinkableActions;
const mapStateToProps = (state: any) => ({
	userNav: state.user.nav,
});
export default connect(mapStateToProps)(LinkableActions);
