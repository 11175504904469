import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import KitchenIcon from '@material-ui/icons/Kitchen';
import StorageIcon from '@material-ui/icons/Storage';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { SideBarStyles } from '../../../hooks/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Loading from '../../../components/loading';

import { UIAction, TenantsActions, ReportActions, UserManagementActions, CabinetActions } from '../../../redux/actions';
import { Constants } from '../../../constants/Constants';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CloudIcon from '@material-ui/icons/Cloud';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import { setSelectedCustomer } from '../../../redux/sagas/ui.saga';
import config from '../../../config';

const links: any = {
	'inventory-management': {
		navGroup: 'inventory',
		label: Constants.PAGE_TITLE.INVENTORY,
	},
	'par-levels': {
		navGroup: 'inventory',
		label: Constants.PAGE_TITLE.PAR_UGAGES,
	},
	'transaction-log': {
		navGroup: 'reports',
		label: 'Transaction Log',
	},
	'inventory-history': {
		navGroup: 'reports',
		label: 'Inventory History',
	},
	'dispensed-products': {
		navGroup: 'reports',
		label: 'Dispensed Products',
	},
	'warehouse-shipments': {
		navGroup: 'reports',
		label: 'Warehouse Shipments',
	},
	'scheduled-reports': {
		navGroup: 'reports',
		label: 'Scheduled Reports',
	},
	'cycle-counts': {
		navGroup: 'reports',
		label: 'Cycle Counts',
	},
	'cabinet-list': {
		navGroup: 'cabinets',
		label: Constants.PAGE_TITLE.CABINETS,
	},
	'cabinet-temperature': {
		navGroup: 'cabinets',
		label: Constants.PAGE_TITLE.CABINET_TEMP,
	},
	'device-config-management': {
		navGroup: 'cabinets',
		label: Constants.PAGE_TITLE.DEVICE_CONFIG_MANAGEMENT,
	},
	'restock-orders': {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.ORDERS,
	},
	'restock-recommendations': {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.RESTOCK_RECOMMENDATIONS,
	},
	'invoice-list': {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.INVOICES,
	},
	'abw-selection': {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.ABW_SELECTION,
	},
	shipments: {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.SHIPMENTS,
	},
	incidents: {
		navGroup: 'alerts',
		label: Constants.PAGE_TITLE.INCIDENTS,
	},
	telemetry: {
		navGroup: 'alerts',
		label: Constants.PAGE_TITLE.TELEMETRY,
	},
	users: {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.USERS,
	},
	'audit-logs': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.AUDIT_LOGS,
	},
	'customer-list': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.CUSTOMERS,
	},
	'product-list': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.PRODUCTS,
	},
	'suspended-transactions': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.SUSPENDED_TRANSACTIONS,
	},
	'invoice-admin': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.ADMIN_INVOICES,
	},
	'order-management': {
		navGroup: 'admin',
		label: Constants.PAGE_TITLE.ORDER_MANAGEMENT,
	},
	'pay-invoices': {
		navGroup: 'orders',
		label: Constants.PAGE_TITLE.PAY_INVOICE,
	},
};

const SideMenuBar = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	const [initLabel, setInitLabel] = React.useState<string>('');

	const classes = SideBarStyles();

	const [customer, setCustomer] = React.useState('' as any);
	const [virtualCabinet, setVirtualCabinet] = React.useState(null as any);

	const isFirstRender = useRef(true);
	useEffect(() => {
		isFirstRender.current = false;
		if (!props.navState) {
			const urlPath = window.location.pathname;
			let path = '';
			let label = '';
			const initNavState: any = {
				inventory: false,
				cabinets: false,
				orders: false,
				alerts: false,
				admin: false,
			};
			Object.keys(links).some((linkPath: string) => {
				if (urlPath.includes(linkPath)) {
					path = '/' + linkPath;
					label = links[linkPath].label;
					initNavState[links[linkPath].navGroup] = true;
					return true;
				}
			});
			setInitLabel(label);
			navigateMenu(label, props.selectedCustomer ? `${path}/${props.selectedCustomer.customerId}` : path);
			props.dispatch(UIAction.setNavState(initNavState));
		}
	}, []);

	useEffect(() => {
		const claims = Object.keys(props.authUser.record.claims);
		if (props.authUser && claims.length === 1 && claims[0] != 'ALL') {
			setCustomer(claims[0]);
		}
	}, [props.authUser]);

	useEffect(() => {
		if (customer) {
			const cabinetInitFilter: any = {
				customerId: customer,
			};
			if (!props.authUser.record.claims['ALL']) {
				cabinetInitFilter.cabinetState = '30';
			}
			props.dispatch(CabinetActions.cabinetsTableRequestStartAction(cabinetInitFilter));
			props.dispatch(
				TenantsActions.getTenantList({
					offset: 0,
					limit: 100,
					skip: 0,
					order: ['id'],
					where: {
						or: [
							{
								name: { like: '%' + customer + '%' },
							},
							{
								customerId: { like: '%' + customer + '%' },
							},
						],
					},
					fields: {
						id: true,
						customerId: true,
						name: true,
					},
				}),
			);
		}
	}, [customer]);

	useEffect(() => {
		if (props.cabinets?.length > 0 && props.tenantList?.length === 1) {
			props.cabinets.forEach((cabinet: any) => {
				if (cabinet.cabinetProperties.consignment === '1') {
					setVirtualCabinet(cabinet);
					props.isCloudUser(true);
				}
			});
		}
	}, [props.cabinets]);

	useEffect(() => {
		if (props.tenantList?.length === 1) {
			props.dispatch(UIAction.setSelectedCustomer(props.tenantList[0]));
		}
	}, [props.tenantsList]);

	const navigateMenu = (title: string, url: string) => {
		props.dispatch(UserManagementActions.getUserListCompleteAction([]));
		props.dispatch(UIAction.setApiError(null));
		props.dispatch(ReportActions.clearInventoryGroup());
		props.dispatch(ReportActions.clearRecentlyVended());
		props.dispatch(TenantsActions.clearTenantsList());
		props.dispatch(
			UIAction.setNavigationState({
				title: title,
				url: url,
			}),
		);
	};

	const generateMenuActiveClass = (menuTitle: string) => {
		if (!props.activeMenu && initLabel && initLabel === menuTitle) {
			return classes.menuItemActive;
		}
		return props.activeMenu && props.activeMenu.title && props.activeMenu.title === menuTitle
			? classes.menuItemActive
			: classes.menuItem;
	};

	const getNavLink = (key: string, path: string, label: string, excludeCustomer?: boolean) => (
		<div key={key}>
			<Link
				className={classes.link}
				to={props.selectedCustomer && !excludeCustomer ? `${path}/${props.selectedCustomer.customerId}` : path}
				onClick={() =>
					navigateMenu(label, props.selectedCustomer ? `${path}/${props.selectedCustomer.customerId}` : path)
				}
			>
				<ListItem button className={`${generateMenuActiveClass(label)} sidebarNavItem`} style={{ paddingLeft: 60 }}>
					<ListItemText primary={label} className={classes.menuItemText} />
				</ListItem>
			</Link>
		</div>
	);

	const handleExpand = (navGroup: string) => {
		const newState = props.open ? !props.navState[navGroup] : true;
		props.dispatch(UIAction.setNavState({ ...props.navState, [navGroup]: newState }));
	};

	if (props.userNav) {
		return (
			<div
				style={{
					position: 'relative',
					maxHeight: 'calc(100vh - 110px)',
					height: '100%',
					overflow: 'auto',
				}}
			>
				<>
					<Link className={classes.link} to={`/dashboard`}>
						<ListItem button className={generateMenuActiveClass('Dashboard')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText primary={'Dashboard'} onClick={() => navigateMenu('Dashboard', `/dashboard`)} />
						</ListItem>
					</Link>
				</>
				{props.userNav.includes('inventory') && (
					<>
						<ListItem button onClick={() => handleExpand('inventory')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<StorageIcon />
							</ListItemIcon>
							<ListItemText primary="Inventory" />
							{props.navState.inventory ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={props.open && props.navState.inventory} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{props.userNav.includes('inventory') &&
									getNavLink('inventory', '/inventory-management', Constants.PAGE_TITLE.INVENTORY)}
								{props.userNav.includes('par') &&
									getNavLink('parLevels', '/par-levels', Constants.PAGE_TITLE.PAR_UGAGES)}
							</List>
						</Collapse>
					</>
				)}
				{props.userNav.includes('cabinets') && (
					<>
						<ListItem button onClick={() => handleExpand('cabinets')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<KitchenIcon />
							</ListItemIcon>
							<ListItemText primary="Devices" />
							{props.navState.cabinets ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={props.open && props.navState.cabinets} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{props.userNav.includes('cabinets') &&
									getNavLink('cabinets', '/cabinet-list', Constants.PAGE_TITLE.CABINETS)}
								{props.userNav.includes('alerts') &&
									props.userNav.includes('cabinets') &&
									getNavLink('cabinetTemperature', '/cabinet-temperature', Constants.PAGE_TITLE.CABINET_TEMP)}
							</List>
						</Collapse>
					</>
				)}
				<>
					<ListItem button onClick={() => handleExpand('reports')}>
						<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
							<AssignmentIcon />
						</ListItemIcon>
						<ListItemText primary="Reports" />
						{props.navState.reports ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={props.open && props.navState.reports} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{props.authUser.record.claims['ALL'] && getNavLink('cycle-counts', '/cycle-counts', 'Cycle Counts', true)}
							{getNavLink('reports-dispensed', '/dispensed-products', 'Dispensed Products')}
							{props.userNav.includes('inventory') &&
								getNavLink('reports-history', '/inventory-history', 'Inventory History')}
							{props.userNav.includes('par') &&
								getNavLink('reports-transactions', '/transaction-log', 'Transaction Log')}
							{getNavLink('reports-shipments', '/warehouse-shipments', 'Warehouse Shipments')}
							{getNavLink('scheduled-reports', '/scheduled-reports', 'Scheduled Reports', true)}
						</List>
					</Collapse>
				</>
				{props.userNav.includes('orders') && (
					<>
						<ListItem button onClick={() => handleExpand('orders')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<AssignmentTurnedInIcon />
							</ListItemIcon>
							<ListItemText primary="Orders" />
							{props.navState.orders ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={props.open && props.navState.orders} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{props.userNav.includes('orders') &&
									getNavLink('orders', '/restock-orders', Constants.PAGE_TITLE.ORDERS)}
								{!props.authUser.record.claims['ALL'] && props.userNav.includes('invoices') &&
									getNavLink('abw-selection', '/abw-selection', Constants.PAGE_TITLE.ABW_SELECTION)}

								{/* feature flag: && (config.apiGateway.host !== 'https://gateway.rightnowinventory.com')*/}
								{props.userNav.includes('invoices') &&
									getNavLink('pay-invoices', '/pay-invoices', Constants.PAGE_TITLE.PAY_INVOICE)}
								{props.userNav.includes('orders') &&
									getNavLink('shipments', '/shipments', Constants.PAGE_TITLE.SHIPMENTS)}
							</List>
						</Collapse>
					</>
				)}
				{props.userNav.includes('alerts') && (
					<>
						<ListItem button onClick={() => handleExpand('alerts')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<ErrorOutlineIcon />
							</ListItemIcon>
							<ListItemText primary="Alerts" />
							{props.navState.alerts ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={props.open && props.navState.alerts} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{props.userNav.includes('incidents') &&
									getNavLink('incidents', '/incidents', Constants.PAGE_TITLE.INCIDENTS)}
								{props.userNav.includes('alerts') &&
									getNavLink('telemetry', '/telemetry', Constants.PAGE_TITLE.TELEMETRY)}
							</List>
						</Collapse>
					</>
				)}
				{props.authUser.record.claims['ALL'] && (
					<>
						<ListItem button onClick={() => handleExpand('admin')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<SettingsApplicationsIcon />
							</ListItemIcon>
							<ListItemText primary="Admin" />
							{props.navState.admin ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={props.open && props.navState.admin} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{props.userNav.includes('users') && getNavLink('users', '/users', Constants.PAGE_TITLE.USERS)}
								{getNavLink('auditLogs', '/audit-logs', Constants.PAGE_TITLE.AUDIT_LOGS)}
								{props.userNav.includes('tenants') &&
									getNavLink('customer', '/customer-list', Constants.PAGE_TITLE.CUSTOMERS)}
								{props.userNav.includes('orders') &&
									getNavLink('orders', '/order-management', Constants.PAGE_TITLE.ORDER_MANAGEMENT)}
								{props.userNav.includes('cabinets') &&
									getNavLink(
										'cabinets',
										'/cabinet-property-settings',
										Constants.PAGE_TITLE.CABINET_PROPERTY_SETTINGS,
										true,
									)}
								{props.userNav.includes('products') &&
									getNavLink('products', '/product-list', Constants.PAGE_TITLE.PRODUCTS)}
								{props.userNav.includes('inventory') && getNavLink('tags', '/tags', Constants.PAGE_TITLE.TAGS, true)}
								{props.userNav.includes('inventory') &&
									getNavLink('suspended-transactions', '/suspended-transactions', 'Suspended Transactions')}
								{props.userNav.includes('personas') &&
									getNavLink('personas', '/personas', Constants.PAGE_TITLE.PERSONAS, true)}
								{props.userNav.includes('invoices') &&
									getNavLink('invoices', '/admin-invoices', Constants.PAGE_TITLE.ADMIN_INVOICES)}
								{props.userNav.includes('cabinets') && props.userNav.includes('device_config') &&
									getNavLink(
										'deviceConfigManagement',
										'/device-config-management',
										Constants.PAGE_TITLE.DEVICE_CONFIG_MANAGEMENT,
									)}
							</List>
						</Collapse>
					</>
				)}
				{props.cloudLinkLoading && (
					<>
						<Loading message="" />
					</>
				)}
				{props.userNav.includes('tenants') && !props.authUser.record.claims['ALL'] && (
					<>
						<Link className={classes.link} to={`/accounts`}>
							<ListItem button className={generateMenuActiveClass('Account Details')}>
								<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText
									primary={'Account Details'}
									onClick={() => navigateMenu('Account Details', `/accounts`)}
								/>
							</ListItem>
						</Link>
					</>
				)}
				{/* *** commented out until support page requirements are ready 
        <Link className={`${classes.link}, ${classes.supportLink}`} to={`/support`}>
          <ListItem button className={generateMenuActiveClass('Support')}>
            <ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
              <CloudIcon />
            </ListItemIcon>
            <ListItemText primary={'Support'} onClick={() => navigateMenu('Support', `/support`)} />
          </ListItem>
        </Link> */}
				<>
					<a
						href="https://rightnowinventory.stonly.com/kb/en"
						target="_blank"
						rel="noopener noreferrer"
						className={classes.link}
					>
						<ListItem button className={generateMenuActiveClass('Help')}>
							<ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
								<HelpIcon />
							</ListItemIcon>
							<ListItemText
								primary={'Help'}
								onClick={() => navigateMenu('Help', `https://rightnowinventory.stonly.com/kb/en`)}
							/>
						</ListItem>
					</a>
				</>
			</div>
		);
	}
	return (
		<div>
			{isDesktopOrLaptop ? (
				<Loading message="" />
			) : (
				<div style={{ width: '60%', margin: '0 auto' }}>
					<Skeleton animation="wave" />
					<Skeleton animation="wave" />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	navState: state.ui.navState,
	activeMenu: state.ui.menuState,
	userNav: state.user.nav,
	selectedCustomer: state.ui.selectedCustomer,
	cloudLinkLoading: state.ui.cloudManagementLinkLoading,
	authUser: state.user.authUser,
	cabinets: state.cabinet.cabinets ? state.cabinet.cabinets.result : [],
	tenantList: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
});
export default connect(mapStateToProps)(SideMenuBar);
