import * as React from 'react';
import { Button, FormControl, Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import { SelectBox } from '../../components/selectBox';

const initProductOptions = (order: any) => {
  const initProduct = { label: `${order.productName} (${order.productId})`, value: order.productId }
  let options = [initProduct]
  order.relatedProducts && order.relatedProducts.forEach((product: any) => {
    options.push({ label: `${product.productName} (${product.productId})`, value: product.productId })
  })
  return options
}

const initBatchOptions = (order: any) => {
  let options: any[] = [{label: 'Empty', value: 'empty'}]
  let list: any[] = []
  const whPrefrenceMap: any = {
    0: '1st',
    1: '2nd',
    2: '3rd',
  }
  Object.keys(order.warehouseInventory).forEach((warehouse: string, index: number) => {
    options.push({listSubheader: true, label: `${whPrefrenceMap[index]} - ${order.warehouseInventory[warehouse].length > 0 ? order.warehouseInventory[warehouse][0].warehouse : '(none)'}`}) 
    order.warehouseInventory[warehouse].forEach((batch: any) => {
      const expiresIn = Math.floor((new Date(batch.expirationDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
      options.push({
        label: `lot: ${batch.lotNumber} - qty: ${batch.warehouseQty} - exp: ${batch.expirationDate.split('T')[0]} (${expiresIn} days)`,
        value: batch.id
      })
      list.push(batch)
    })
  })
  return {batchOptions: options, batchList: list}
}

const ReleaseOrderModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
  const productOptions = initProductOptions(props.order)
  const {batchOptions, batchList} = initBatchOptions(props.order)
  const showBatchSelection = Object.values(props.order.warehouseInventory).some((whGroup) => Array.isArray(whGroup) && whGroup.length > 0);
	const [qty, setQty] = React.useState<any>(props.order.recommendedQty);
  const [cabinetId, setCabinetId] = React.useState<any>(props.order.cabinets && props.order.cabinets[0] ? props.order.cabinets[0].cabinetId : null)
  const [productId, setProductId] = React.useState<any>(props.order.productId)
  const [batchId, setBatchId] = React.useState<any>(props.order.defaultBatch?.id || 'empty')
  const [secondaryBatches, setSecondaryBatches] = React.useState<any>([])
	const [errors, setErrors] = React.useState<any>({});

	const submit = () => {
		const formErrors: any = {};
		if (isNaN(qty) || qty < 1) {
			formErrors['qty'] = 'Please enter a valid qty.';
		}
    if (!productId) {
      formErrors['productId'] = 'Please select a valid product ID.';
    }
    if (!cabinetId && props.order.cabinets && props.order.cabinets.length > 0) {
      formErrors['cabinetId'] = 'There is no cabinet associated with this recommendation. Cannot release order.';
    }
    if (batchId === 'empty' && Object.keys(props.order.defaultBatch).length > 0) {
      formErrors['batchId'] = 'Please select a batch.';
    }
    secondaryBatches.forEach((batch: any, index: number) => {
      if (!batch.batchId) {
        formErrors['secondaryBatches'] = formErrors['secondaryBatches'] || []
        formErrors['secondaryBatches'][index] = {batchId: 'Please select a batch.'}
      }
      if (isNaN(batch.qty) || batch.qty < 1) {
        formErrors['secondaryBatches'] = formErrors['secondaryBatches'] || []
        formErrors['secondaryBatches'][index] = formErrors['secondaryBatches'][index] ? {...formErrors['secondaryBatches'][index], qty: 'Please enter a valid qty.'} : {qty: 'Please enter a valid qty.'}
      }
    })
		setErrors(formErrors);

		if (Object.keys(formErrors).length > 0) {
			return;
		}

    const selectedBatch = (batchId && batchId !== 'empty') ? batchList.find((batches: any) => batches.id === batchId) : null

		let orderPayload = [{
			customerId: props.order.customerId,
      cabinetId: cabinetId,
      productId: productId,
      qty: qty,
      inventoryStatus: props.order.inventoryStatus,
      warehouse: selectedBatch ? selectedBatch.warehouse : '',
      expiration: selectedBatch ? selectedBatch.expirationDate : '',
      lot: selectedBatch ? selectedBatch.lotNumber : '',
      poType: ''
		}]

    secondaryBatches.forEach((batch: any) => {
      const secondaryBatch = batchList.find((batchOption: any) => batchOption.id === batch.batchId)
      if (secondaryBatch) {
        orderPayload.push({
          customerId: props.order.customerId,
          cabinetId: cabinetId,
          productId: productId,
          qty: batch.qty,
          inventoryStatus: props.order.inventoryStatus,
          warehouse: secondaryBatch.warehouse,
          expiration: secondaryBatch.expirationDate,
          lot: secondaryBatch.lotNumber,
          poType: ''
        })
      }
    })
    
    props.submitOrder(orderPayload);
    props.close(false);
	};

	React.useEffect(() => {
		setErrors({});
	}, [qty, cabinetId, productId, batchId]);

	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 'auto',
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={() => props.close(false)}
			>
				<CancelIcon />
			</div>
			<h2>Modify & Release Order</h2>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					Customer Id: <br />
					<b>{props.order.customerId}</b>
				</Grid>
        {productOptions.length === 1 && 
          <Grid item xs={6}>
            Product: <br />
            <b>{`${props.order.productName} (${props.order.productId})`}</b>
          </Grid>
        }
        {props.order.warnings &&
          <Grid item xs={12}>
            {props.order.warnings.map((warning: string) => (<><div style={{color: 'red'}}>{warning}</div><br/></>))}
            {errors?.cabinetId && <div style={{color: 'red'}}>{errors.cabinetId}</div>}
          </Grid>
        }
			</Grid>
			<div>
      <div style={{ margin: '20px 0 0' }}>
        {/* {props.order.cabinets && props.order.cabinets.length > 1 && 
          <FormControl variant="outlined" style={{ marginRight: 10 }}>
            <SelectBox
              inputLabel={'Select Cabinet'}
              emptyItemLabel={'Choose'}
              style={{ width: 150 }}
              selected={cabinetId}
              error={errors.cabintId ? true : false}
              errorText={errors.cabinetId || ''}
              listItems={props.order.cabinets.map((cabinetOption: any) => (
                {label: `${cabinetOption.cabinetId} - ${cabinetOption.cabinetType}`, value: cabinetOption.cabinetId})
              )}
              onChangeItem={(value: any) => {
                setCabinetId(value);
              }}
            />
          </FormControl>
        } */}
        {productOptions.length > 1 && 
          <FormControl variant="outlined" style={{ marginRight: 10 }}>
            <SelectBox
              inputLabel={'Select Product'}
              emptyItemLabel={'Choose'}
              style={{ width: 150 }}
              selected={productId}
              listItems={productOptions}
              error={errors.productId ? true : false}
              errorText={errors.productId|| ''}
              onChangeItem={(value: any) => {
                setProductId(value);
              }}
            />
          </FormControl>
        }
			</div>

        <div style={{ margin: '20px 0 0' }}>
          {showBatchSelection && <FormControl variant="outlined" style={{ marginRight: 10 }}>
            <SelectBox
              inputLabel={'Select Batch'}
              style={{ width: 150 }}
              selected={batchId}
              error={errors.batchId ? true : false}
              errorText={errors.batchId || ''}
              listItems={batchOptions}
              onChangeItem={(value: any) => {
                setBatchId(value);
              }}
            />
          </FormControl>}
          <FormControl variant="outlined" style={{ marginRight: 10 }}>
            <TextField
              variant="outlined"
              type="number"
              label="Qty"
              style={{ width: 105 }}
              InputProps={{ inputProps: { min: 0, step: 1 } }}
              value={qty >= 0 ? qty : null}
              error={errors.qty ? true : false}
              helperText={errors.qty || ''}
              onChange={event => {
                setQty(parseInt(event.target.value));
              }}
              required
            />
          </FormControl>
          {batchId !== 'empty' && showBatchSelection && 
            <FormControl>
              <Button
                type="button"
                className={commonStyles.searchButton}
                variant="contained"
                color="primary"
                onClick={() => setSecondaryBatches([...secondaryBatches, {batchId: null, qty: null}])}
              >
                Add Batch
              </Button>
          </FormControl>
          }
        </div>
        {secondaryBatches.map((batch: any, index: number) => (
          <div style={{ margin: '20px 0 0' }}>
            <FormControl variant="outlined" style={{ marginRight: 10 }}>
              <SelectBox
                inputLabel={'Select Batch'}
                style={{ width: 150 }}
                selected={batch.batchId}
                error={errors?.secondaryBatches && errors?.secondaryBatches[index]?.batchId ? true : false}
                errorText={errors?.secondaryBatches && errors?.secondaryBatches[index]?.batchId || ''}
                listItems={batchOptions.filter((option: any) => 
                  option.value !== 'empty' 
                  && option.value !== batchId 
                  && !secondaryBatches.some((secondaryBatch: any, thisIndex: number) => (secondaryBatch.batchId === option.value && thisIndex !== index))
                )}
                onChangeItem={(value: any) => {
                  let secondaryBatchesCopy = [...secondaryBatches]
                  secondaryBatchesCopy[index].batchId = value
                  setSecondaryBatches(secondaryBatchesCopy)
                }}
              />
            </FormControl>
            <FormControl variant="outlined" style={{ marginRight: 10 }}>
              <TextField
                variant="outlined"
                type="number"
                label="Qty"
                style={{ width: 105 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
                value={batch.qty >= 0 ? batch.qty : null}
                error={errors?.secondaryBatches && errors?.secondaryBatches[index]?.qty ? true : false}
                helperText={errors?.secondaryBatches && errors?.secondaryBatches[index]?.qty || ''}
                onChange={event => {
                  let secondaryBatchesCopy = [...secondaryBatches]
                  secondaryBatchesCopy[index].qty = parseInt(event.target.value)
                  setSecondaryBatches(secondaryBatchesCopy)
                }}
                required
              />
            </FormControl> 
            <FormControl>
              <Button
                type="button"
                className={commonStyles.cancelBtn}
                variant="contained"
                color="primary"
                onClick={() => {
                  let secondaryBatchesCopy = [...secondaryBatches]
                  secondaryBatchesCopy.splice(index, 1)
                  setSecondaryBatches(secondaryBatchesCopy)
                }}
              >
                Remove Batch
              </Button>
            </FormControl>
          </div>
        ))}
        <div>
          <FormControl>
            <Button
              type="button"
              className={commonStyles.searchButton}
              variant="contained"
              color="primary"
              onClick={submit}
            >
              SUBMIT
            </Button>
          </FormControl>
        </div>
			</div>
		</div>
	);
};

export default ReleaseOrderModal;
