import React from 'react';
import { connect } from 'react-redux';
import { TenantsActions } from '../../../redux/actions';
import { Button, Grid, FormControl, TextField, TextareaAutosize, FormControlLabel } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../../hooks/styles';
import Loading from '../../../components/loading';
import { SelectBox } from '../../../components/selectBox';
import dataList from '../../../constants/dataList';
import FormHelperText from '@mui/material/FormHelperText';

const CustomerFormModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());

	const getInitInputs = () => ({
		customerId: props.customerData.customerId || '',
		name: props.customerData.name || '',
		address1: props.customerData.address1 || '',
		email: props.customerData.userEmail || '',
		phone: props.customerData.userPhone || '',
		zip: props.customerData.zip || '',
		manualSplitBill: props.customerData.manualSplitBill || '',
		autoSplitBill: props.customerData.autoSplitBill || '',
		manualSplitBillDefault: props.customerData.manualSplitBillDefault || 'A',
		replenDowExclusions: props.customerData.replenDowExclusions ? props.customerData.replenDowExclusions.split(',') : [],
		id: props.customerData.id || '',
		notes: props.customerData.notes || ''
	});

	const [inputs, setInputs] = React.useState<any>(getInitInputs());
	const [formErrors, setFormErrors] = React.useState<any>({});

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [submitError, setSubmitError] = React.useState<string>('');

	const handleCancel = () => {
		props.closeModal();
	};

	const handleSetInputs = (key: string, value: any) => {
		setInputs({ ...inputs, [key]: value });
		console.log(inputs);
		console.log('Input value: ', value);
		setFormErrors({ ...formErrors, [key]: null });
		setSubmitError('');
	};

	const handleSave = () => {
		const errors: any = {};
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setIsLoading(true);

		const onSuccess = () => {
			setIsLoading(false);
			props.getCustomers();
			props.closeModal();
		};

		const onError = () => {
			setIsLoading(false);
			setSubmitError('Failed to save customer');
		};

		const requestBody = props.customerData ? getEditRequestBody() : getAddRequestBody();

		const payload = {
			requestBody: requestBody,
			success: onSuccess,
			error: onError,
		};

		const submitFn = props.customerData ? props.updateCustomer : props.createCustomer;

		submitFn(payload);
	};

	const getAddRequestBody = () => ({
		customerId: inputs.customerId,
	});

	const getEditRequestBody = () => ({
		id: inputs.id,
		manualSplitBill: inputs.manualSplitBill,
		autoSplitBill: inputs.autoSplitBill,
		manualSplitBillDefault: inputs.manualSplitBillDefault,
		replenDowExclusions: inputs.replenDowExclusions.length > 0 ? inputs.replenDowExclusions.join(',') : '',
		notes: inputs.notes || null
	});

	return (
		<div style={modalStyle} className={modalClasses.paper}>
			<div>
				<h3 id="simple-modal-title">{props.customerData ? 'Edit Customer' : 'Add New Customer'}</h3>
				<Grid container>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Customer Id"
								variant="outlined"
								value={inputs.customerId}
								onChange={event => {
									handleSetInputs('customerId', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Customer Name"
								variant="outlined"
								value={inputs.name}
								onChange={event => {
									handleSetInputs('name', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Address"
								variant="outlined"
								value={inputs.address1}
								onChange={event => {
									handleSetInputs('address1', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Zip"
								variant="outlined"
								value={inputs.zip}
								onChange={event => {
									handleSetInputs('zip', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Email"
								variant="outlined"
								value={inputs.email}
								onChange={event => {
									handleSetInputs('email', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Phone"
								variant="outlined"
								value={inputs.phone}
								onChange={event => {
									handleSetInputs('phone', event.target.value);
								}}
								disabled={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel={'Manual Split Bill'}
								listItems={dataList.ManualSplitBill}
								selected={inputs.manualSplitBill}
								onChangeItem={(value: any) => {
									handleSetInputs('manualSplitBill', value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel={'Auto Split Bill'}
								listItems={dataList.ManualSplitBill}
								selected={inputs.autoSplitBill}
								onChangeItem={(value: any) => {
									handleSetInputs('autoSplitBill', value);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel={'Auto Invoice Account Selection'}
								listItems={dataList.InvoiceAbw}
								selected={inputs.manualSplitBillDefault}
								onChangeItem={(value: any) => {
									handleSetInputs('manualSplitBillDefault', value);
								}}
							/>
							<FormHelperText>Sets the account that an invoice will default to if no selection is made.</FormHelperText>
						</FormControl>
						<FormControl className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								multiple={true}
								inputLabel={'DOW Replen Exclusions'}
								listItems={[{label: 'Monday', value: 'Monday'},{label: 'Tuesday', value: 'Tuesday'},{label: 'Wednesday', value: 'Wednesday'},{label: 'Thursday', value: 'Thursday'},{label: 'Friday', value: 'Friday'},{label: 'Saturday', value: 'Saturday'},{label: 'Sunday', value: 'Sunday'},]}
								selected={inputs.replenDowExclusions}
								onChangeItem={(value: any) => {
									handleSetInputs('replenDowExclusions', value);
								}}
							/>
							<FormHelperText>Sets the days that orders should not be sent to this customer.</FormHelperText>
						</FormControl>
						<FormControl className={classes.formControl}>
							<p 
								className={classes.formControlLabel}
							>
								Notes
							</p>
							<TextareaAutosize
								minRows={3}
								maxRows={3}
								placeholder="Enter notes here"
								value={inputs.notes || ''}
								onChange={(e: any) => {
									handleSetInputs('notes', e.target.value);
								}}
							/>
						</FormControl>
					</Grid>
					{submitError && <p className={classes.errorMsg}>{submitError}</p>}
					{isLoading ? (
						<Loading message="" />
					) : (
						<Grid item xs={12}>
							<FormControl className={classes.formControl}>
								<Button
									onClick={handleSave}
									type="button"
									style={{ marginTop: 12 }}
									className={classes.searchButton}
									variant="contained"
									color="primary"
								>
									{' '}
									SAVE{' '}
								</Button>
							</FormControl>
							<FormControl className={classes.formControl}>
								<Button
									onClick={handleCancel}
									type="button"
									style={{ marginTop: 12, marginLeft: 0 }}
									className={classes.cancelBtn}
									variant="contained"
									color="default"
								>
									{' '}
									CANCEL{' '}
								</Button>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	createCustomer: (payload: any) => () => {}, //TODO adding customers with optional data, currently adding a customer only syncs with SAP
	updateCustomer: (payload: any) => dispatch(TenantsActions.updateCustomer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFormModal);
