import config from '../../config';

export const API_BASE_URL = {
	REPORTS: process.env.REACT_APP_API_URL + ':3000',
	CABINETS: process.env.REACT_APP_API_URL + ':3002',
	TENANTS: process.env.REACT_APP_API_URL + ':3001',
	API_GATEWAY: config.apiGateway.host,
};

export const REPORTS = {
	INVENTORY: {
		GROUP: `${API_BASE_URL.API_GATEWAY}/reports/inventory/group`,
		HISTORY: {
			LIST: `${API_BASE_URL.API_GATEWAY}/reports/inventory/history`,
		},
		CURRENT: {
			LIST: `${API_BASE_URL.API_GATEWAY}/reports/inventory/current`,
		},
		TRANSACTION: `${API_BASE_URL.API_GATEWAY}/transactions`, // References fdi-par-service
		TRANSACTION_EXPORT: `${API_BASE_URL.API_GATEWAY}/reports/transaction?type=csv`,

		USAGE: `${API_BASE_URL.API_GATEWAY}/reports/usage`,
	},

	DISPENSED_PRODUCTS: `${API_BASE_URL.API_GATEWAY}/reports/dispensed`,
	WAREHOUSE_SHIPMENTS: `${API_BASE_URL.API_GATEWAY}/reports/shipments`,

	//CRUD for scheduled reports
	CREATE_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/reportSubscription`,
	DELETE_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/deleteSubscription`,
	EDIT_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/reportSubscription`,
	SEND_ONE_TIME_REPORT: `${API_BASE_URL.API_GATEWAY}/reports/generate-scheduled`,
	UPDATE_QUEUE_ITEM: `${API_BASE_URL.API_GATEWAY}/reports/reportqueue`,
};

export const CABINET = {
	FILTER: `${API_BASE_URL.API_GATEWAY}/cabinets/filter`,
	LIST: `${API_BASE_URL.API_GATEWAY}/cabinets/list`,
	TELEMETRY_METRICS: `${API_BASE_URL.API_GATEWAY}/telemetry/metrics`,
	PROPERTIES: `${API_BASE_URL.API_GATEWAY}/cabinets/cabinetProperties`,
	EDGE_CONFIG_PROPERTIES: `${API_BASE_URL.API_GATEWAY}/cabinets/cabinet-properties`,
	NEW: `${API_BASE_URL.API_GATEWAY}/cabinets/newCabinet`,
	STATE: `${API_BASE_URL.API_GATEWAY}/cabinets/states`,
	DETAILS: `${API_BASE_URL.API_GATEWAY}/cabinets/details`,
	TEMPERATURE: `${API_BASE_URL.API_GATEWAY}/telemetry/temperature`,
	URL: `${API_BASE_URL.API_GATEWAY}/cabinets`,
	CABINET_USERS: `${API_BASE_URL.API_GATEWAY}/cabinetUsers`,
	VIPC: `${API_BASE_URL.API_GATEWAY}/cabinet/vipc`,
	PAR: `${API_BASE_URL.API_GATEWAY}/par`,
	FLEX_CONFIG: `${API_BASE_URL.API_GATEWAY}/cabinet/config`,
	MANUAL_RECEIPT: `${API_BASE_URL.API_GATEWAY}/shipping-receipt/flex/manualReceipt`,
	COMMAND: `${API_BASE_URL.API_GATEWAY}/cabinet/command`,
	DEVICE_TAGS: `${API_BASE_URL.API_GATEWAY}/cabinet/device_tags`,
	RECEIPTS: `${API_BASE_URL.API_GATEWAY}/cabinet/state/receipts`,
	CONNECTIVITY: `${API_BASE_URL.API_GATEWAY}/telemetry/cabinet/connectivity`,
	LTEWIFI: `${API_BASE_URL.API_GATEWAY}/telemetry/connectivity`,
};

export const TENANTS = {
	LIST: `${API_BASE_URL.API_GATEWAY}/tenants/filter`,
	GET: `${API_BASE_URL.API_GATEWAY}/tenants`,
	ADDITIONAL_INFO: `${API_BASE_URL.API_GATEWAY}/tenants`,
	UPDATE: `${API_BASE_URL.API_GATEWAY}/tenants`,
	PAR: `${API_BASE_URL.API_GATEWAY}/par`,
	PRODUCTS: `${API_BASE_URL.API_GATEWAY}/par/customer/settings`,
	CLASS_OF_TRADE_OPTIONS: `${API_BASE_URL.API_GATEWAY}/tenants/classOfTradeOptions`,
	FACILITY_TYPE_OPTIONS: `${API_BASE_URL.API_GATEWAY}/tenants/facilityTypeOptions`,
};

export const NOTIFICATIONS = {
	LIST: `${API_BASE_URL.API_GATEWAY}/export/emailActivity`,
	GET: `${API_BASE_URL.API_GATEWAY}/export/messageDetails`,
};

export const INVOICES = {
	LIST: `${API_BASE_URL.API_GATEWAY}/invoice`,
	SESSION_TOKEN: `${API_BASE_URL.API_GATEWAY}/invoice/token`,
	PAYMENT_CONFIRMATION: `${API_BASE_URL.API_GATEWAY}/invoice/creditResponse`,
	INVOICE_REQUESTS: `${API_BASE_URL.API_GATEWAY}/invoice-requests`,
	INVOICE_REQUESTS_BULK: `${API_BASE_URL.API_GATEWAY}/invoice-requests`,
};

export const INVENTORY_MANAGEMENT = {
	URL: `${API_BASE_URL.API_GATEWAY}/inventory`,
	LIST_PAR: `${API_BASE_URL.API_GATEWAY}/par`,
	LIST_INVENTORY: `${API_BASE_URL.API_GATEWAY}/inventory`,
	POST_USAGE: `${API_BASE_URL.API_GATEWAY}/vc/inventory/usage`,
	PATCH_RECEIPT: `${API_BASE_URL.API_GATEWAY}/vc/inventory/receipt`,
	PRODUCT_TAG_SERIALS: `${API_BASE_URL.API_GATEWAY}/inventory/product-tag-serials`,
};

export const USER = {
	URL: `${API_BASE_URL.API_GATEWAY}/users`,
	CUSTOMER_USER: `${API_BASE_URL.API_GATEWAY}/customer-user`,
};

export const SUPPORT = {
	URL: `${API_BASE_URL.API_GATEWAY}/export/sendNotificationFromUI/SYSTEM`,
};

export const USER_PROPERTY = {
	URL: `${API_BASE_URL.API_GATEWAY}/userproperties`,
};

export const ALERTS = {
	URL: `${API_BASE_URL.API_GATEWAY}/telemetry/alerts`,
	INCIDENTS: `${API_BASE_URL.API_GATEWAY}/incidents`,
	INCIDENT: `${API_BASE_URL.API_GATEWAY}/incident`,
	INCIDENT_RULES: `${API_BASE_URL.API_GATEWAY}/incident-rules`,
	NOTIFICATION_TYPES: `${API_BASE_URL.API_GATEWAY}/export/notificationTypes`,
	CHANNEL_TYPES: `${API_BASE_URL.API_GATEWAY}/export/channelTypes`,
	CHANNEL_GROUPS: `${API_BASE_URL.API_GATEWAY}/export/channelGroups`,
};

export const PRODUCTS = {
	URL: `${API_BASE_URL.API_GATEWAY}/products`,
	GROUPS: `${API_BASE_URL.API_GATEWAY}/products/groups`,
};

export const ORDERS = {
	URL: `${API_BASE_URL.API_GATEWAY}/orders`,
	SHIPMENTS: `${API_BASE_URL.API_GATEWAY}/shipments`,
};

export const getRestockOrderDetailsUrl = (type: string, orderNo: string) => {
	switch (type) {
		case 'RESTOCK_REQUEST':
			return `${API_BASE_URL.API_GATEWAY}/orders/requests/${orderNo}?limit=10000`;
		case 'IG_SHIPMENT_ITEMS':
			return `${API_BASE_URL.API_GATEWAY}/order/${orderNo}/items?limit=10000`;
		case 'WAREHOUSE_SHIPMENT':
			return `${API_BASE_URL.API_GATEWAY}/shipments?orderNo=${orderNo}&limit=10000`;
		case 'TRANSACTIONS':
			return `${API_BASE_URL.API_GATEWAY}/orders/${orderNo}/received?limit=10000`;
		case 'SAP_ORDERS':
			return `${API_BASE_URL.API_GATEWAY}/orders/sapOrders/${orderNo}?limit=10000`;
		default:
			console.error('Unknown getRestockOrderDetailsUrl type:', type);
			return '';
	}
};

export const AUDIT = {
	URL: `${API_BASE_URL.API_GATEWAY}/audit`,
};

export const CC_PAYABLE_ELIGIBILITY = {
	URL: `${API_BASE_URL.API_GATEWAY}/users/credit-card-payable`,
};
