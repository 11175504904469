import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Grid, Modal, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { PracticePageStyles } from '../../../hooks/styles';
import { GridContainer } from '../../../hooks/functions';
import { useParams } from 'react-router-dom';
import { TenantsActions } from '../../../redux/actions';
import { CustomerListFilter } from '../../../redux/models/tenants/tenants';
import MediaQuery from 'react-responsive';
import CustomerFormModal from '../list/customer-form-modal.component';

interface BasicInformationProps {
	tenantsDetails: any;
}

const BasicInformation = (props: BasicInformationProps) => {
	const [customerFilter] = React.useState(new CustomerListFilter());
	const classes = PracticePageStyles();
	const { customerId } = useParams<any>();
	const dispatch = useDispatch();
	const isFirstRender = useRef(true);
	const [openEditModal, setOpenEditModal] = React.useState(false);

	const fetchResult = () => {
		isFirstRender.current = false;
		customerFilter.primaryContact = true;
		customerFilter.customerId = customerId;
		dispatch(
			TenantsActions.getTenantBasicInfo({
				offset: 0,
				limit: 1,
				skip: 0,
				order: ['id'],
				where: customerFilter,
				/*fields: {
                    id: true,
                    customerId: true,
                    name: true,
                    type: true,
                    address1: true,
                    address2: true,
                },*/
			}),
		);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			fetchResult();
		}
	});

	return (
		<div>
			<>
				<Paper className={clsx(classes.paper, 'container-wrapper')}>
					{GridContainer([
						{
							label: 'Customer Name',
							value:
								props.tenantsDetails && props.tenantsDetails.result
									? props.tenantsDetails.result[0].name
									: '-----------------',
						},
					])}

					{GridContainer([
						{
							label: 'Full Address',
							value:
								props.tenantsDetails && props.tenantsDetails.result
									? props.tenantsDetails.result[0].address1
									: '-----------------',
						},
					])}
					<MediaQuery minDeviceWidth={1224}>
						{GridContainer([
							{
								label: 'Contact Person Name',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userFirstName +
										  ' ' +
										  props.tenantsDetails.profile[customerId].userLastName
										: '-----------------',
							},
							{
								label: 'Phone No',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userPhone
										: '-----------------',
							},
							{
								label: 'Email Id',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userEmail
										: '-----------------',
							},
							{ label: '', value: '' },
						])}
					</MediaQuery>
					<MediaQuery maxDeviceWidth={1223}>
						{GridContainer([
							{
								label: 'Contact Person Name',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userFirstName +
										  ' ' +
										  props.tenantsDetails.profile[customerId].userLastName
										: '-----------------',
							},
						])}
						{GridContainer([
							{
								label: 'Phone No',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userPhone
										: '-----------------',
							},
							{
								label: 'Email Id',
								value:
									props.tenantsDetails && props.tenantsDetails.profile && props.tenantsDetails.profile[customerId]
										? props.tenantsDetails.profile[customerId].userEmail
										: '-----------------',
							},
						])}
					</MediaQuery>

					{GridContainer([
						{ label: 'Customer ID', value: customerId },
						{
							label: 'Status',
							value:
								props.tenantsDetails && props.tenantsDetails.result && props.tenantsDetails.result[0].status === '1'
									? 'Complete'
									: 'InComplete',
						},
						{ label: '', value: '' },
						{ label: '', value: '' },
					])}
					{GridContainer([
						{
							label: 'Manual Split Bill',
							value:
								props.tenantsDetails && props.tenantsDetails.result
									? props.tenantsDetails.result[0].manualSplitBill
									: '',
						},
						{
							label: 'Auto Split Bill',
							value:
								props.tenantsDetails && props.tenantsDetails.result ? props.tenantsDetails.result[0].autoSplitBill : '',
						},
						{
							label: 'ABW Account',
							value:
								props.tenantsDetails && props.tenantsDetails.result ? props.tenantsDetails.result[0].manualSplitBillDefault : '',
						},
						{
							label: 'Replen DOW Exclusions',
							value:
								props.tenantsDetails && props.tenantsDetails.result ? props.tenantsDetails.result[0].replenDowExclusions || 'None' : 'None',
						},
					])}
					{props.tenantsDetails && props.tenantsDetails.result && props.tenantsDetails.result[0].notes && 
						GridContainer([
							{
								label: 'Customer Notes',
								value: props.tenantsDetails.result[0].notes
							},
						])
					}
					<Grid container>
						<Grid item xs={12}>
							<Button
								type="button"
								color="primary"
								variant="contained"
								className={classes.searchButton}
								style={{ marginTop: 12 }}
								onClick={() => setOpenEditModal(true)}
							>
								EDIT
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</>
			{props.tenantsDetails && (
				<div>
					<Modal
						disableBackdropClick={true}
						open={openEditModal}
						onClose={() => {
							setOpenEditModal(false);
						}}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>
							<CustomerFormModal
								getCustomers={fetchResult}
								customerData={{
									...props.tenantsDetails.profile[customerId],
									...props.tenantsDetails.result[0],
								}}
								closeModal={() => {
									setOpenEditModal(false);
								}}
							/>
						</div>
					</Modal>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		tenantsDetails: state.tenants.basicInfo,
	};
};

export default connect(mapStateToProps)(BasicInformation);
