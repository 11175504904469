import * as React from 'react';
import { AppBar, Badge, Button, Fade, FormControl, Grid, IconButton, Menu, MenuItem, Modal, Tab, Tabs } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { CommonStyles, getModalClasses, getModalStyle } from '../../hooks/styles';
import { getMuiTheme } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import Loading from '../../components/loading';
import EmptyContent from '../../components/emptyContent';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import { GetUserTimezone, valueMapper } from '../../hooks/functions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotesIcon from '@mui/icons-material/TextSnippet';
import InfoIcon from '@mui/icons-material/Info';
import ReleaseOrderModal from './release-order.modal';
import CreateOrderModal from './create-order.modal';
import ConfirmDialog from '../../components/dialog/confirm';
import SetParModal from '../customer/details/set-par.modal';
import OrdersComponent from '../orders/orders.component';

const ActionsMenu: React.FC<any> = props => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
	return (
		<div>
			<IconButton
				style={{ padding: 0 }}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={e => {
					setMenuOpen(!menuOpen);
					setMenuAnchor(e.currentTarget);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				keepMounted
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
					setMenuAnchor(null);
				}}
				PaperProps={{
					style: {
						height: 'auto',
						width: '20ch',
					},
				}}
				MenuListProps={{ id: 'restock-recommendation-actions' }}
			>
				{props.actions.modifyAndReleaseOrder && (
					<MenuItem
						id={'modify-and-release-order'}
						onClick={() => {
							props.actions.modifyAndReleaseOrder(props.dataIndex);
							setMenuOpen(false);
						}}
					>
						Modify & Release Order
					</MenuItem>
				)}
				{props.actions.editParSettings && (
					<MenuItem
						id={'edit-par-settings'}
						onClick={() => {
							props.actions.editParSettings(props.dataIndex);
							setMenuOpen(false);
						}}
					>
						Edit Par Settings
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};

const OrderManagementComponent: React.FC<any> = (props: any) => {
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());
	const classes = CommonStyles();
	const tableTheme = getMuiTheme();
	tableTheme.overrides.MUIDataTableHeadCell = {
		root: {
			fontSize: 12,
		},
		hintIconAlone: {
			fontSize: '1.2em !important',
			marginTop: '3px !important',
			color: '#757575',
		},
	};
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const firstRender = React.useRef(true);
	const [tabView, setTabView] = React.useState<string>('restockOrders');
	const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [tableHeight, setTableHeight] = React.useState(0);
	const [selectedRows, setSelectedRows] = React.useState<any[]>([])
	const [selectedTableRowIndexes, setSelectedTableRowIndexes] = React.useState<any[]>([])
	const [modalOpen, setModalOpen] = React.useState(false);
	const [orderToModify, setOrderToModify] = React.useState<any>(null)
	const [modalAction, setModalAction] = React.useState('');
	const [confirmOpen, setConfirmOpen] = React.useState(false);
	const [hoverData, setHoverData] = React.useState<any>(null);
	const [showToolTip, setShowToolTip] = React.useState(false);
	const [toolTopPosition, setToolTipPosition] = React.useState({x: 0, y: 0} as any);
	const [parToEdit, setParToEdit] = React.useState<any>(null);
	const [submitPar, setSubmitPar] = React.useState(false);

	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
		props.setDefaultStatusFilter(["Urgent", "Replen", "Opportunity"])
	}, []);

	React.useEffect(() => {
		if (parToEdit) {
			setModalAction('setPar')
			setModalOpen(true)
		}
	}, [parToEdit])

	const handleTabChange = (event: any, newValue: string) => {
		setShowToolTip(false)
		if (newValue === tabView) {
			return;
		}
		setSelectedRows([])
		setSelectedTableRowIndexes([])
		setSortOrder('');
		setTabView(newValue);
		setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
	};

	const renderBatch = (dataIndex: number) => {
		const defaultBatch = props.inventory[dataIndex].defaultBatch
		if (defaultBatch && Object.keys(defaultBatch).length > 0) {
			let batchTxt = `${defaultBatch.warehouse}`;
			if (defaultBatch.lotNumber) {
				batchTxt += ` - ${defaultBatch.lotNumber}`;
			}
			if (defaultBatch.warehouseQty) {
				batchTxt += ` - ${defaultBatch.warehouseQty}`;
			}

			return batchTxt;
		} else {
			return 'Empty'
		}
	}

	const renderStatus = (dataIndex: number) => {
		const statusColors: any = { Replen: '#ebc334', Urgent: '#eb3734' };
		return props.inventory[dataIndex] ? (
			<b
				style={
					statusColors[props.inventory[dataIndex].inventoryStatus]
						? { padding: 5, borderRadius: 4, backgroundColor: statusColors[props.inventory[dataIndex].inventoryStatus] }
						: {}
				}
			>
				{props.inventory[dataIndex].inventoryStatus}
			</b>
		) : (
			''
		);
	};

	const handleModalClose = (refreshData: boolean | undefined) => {
		setModalOpen(false);
		setSubmitPar(false);
		if (refreshData) {
			props.getInventory(processTableFilter())
		}
	};

	const getActions = (dataIndex: number) => {
		const actions: any = {};
		actions.modifyAndReleaseOrder = () => {
			setModalOpen(true)
			setModalAction('modifyAndReleaseOrder')
			setOrderToModify(props.inventory[dataIndex])
		}
		actions.editParSettings = () => {
			props.getParRecord({
				filter: JSON.stringify({
					where: {
						and: [{
							customerId: props.inventory[dataIndex].customerId
						}, 
						{
							productId: props.inventory[dataIndex].mbrxProductId ? props.inventory[dataIndex].mbrxProductId : props.inventory[dataIndex].productId
						}]
					}
				}),
				onSuccess: (data: any) => {
					setParToEdit(data)
				}
			})
			
		}
		return actions;
	};

	const columnData = [
		{
			label: 'Customer Id',
			name: 'customerId',
			display: true,
			sort: true,
			customBodyRenderLite: (dataIndex: number) => {
				const recommendation = props.inventory[dataIndex]
				if (recommendation.customerNote) {
					return <div style={{display: 'flex', alignItems: 'center'}}>
						{recommendation.customerId}
						<NotesIcon 
							style={{marginLeft: 5}} 
							onMouseMove={(e: any) => {
								setToolTipPosition({x: e.pageX, y: e.pageY})
							}}
							onMouseEnter={(e: any) => {
								setHoverData(recommendation.customerNote)
								setShowToolTip(true)
							}}
							onMouseLeave={(e: any) => {
								setShowToolTip(false)
							}}
						/>
					</div>
				}
				return recommendation.customerId
			}
		},
		{
			label: 'Product',
			name: 'productName',
			display: true,
			sort: true,
			customBodyRenderLite: (dataIndex: number) => {
				const recommendation = props.inventory[dataIndex]
				if (recommendation.customerProductNote) {
					return <div style={{display: 'flex', alignItems: 'center'}}>
						{recommendation.productName}
						<NotesIcon 
							style={{marginLeft: 5}} 
							onMouseMove={(e: any) => {
								setToolTipPosition({x: e.pageX, y: e.pageY})
							}}
							onMouseEnter={(e: any) => {
								setHoverData(recommendation.customerProductNote)
								setShowToolTip(true)
							}}
							onMouseLeave={(e: any) => {
								setShowToolTip(false)
							}}
						/>
					</div>
				}
				return recommendation.productName
			}
		},
		{
			label: 'Product Id',
			name: 'productId',
			display: false,
			sort: true,
		},
		{
			label: 'Device Type',
			name: 'deviceType',
			display: true,
			sort: true,
			customBodyRenderLite: (dataIndex: number) => {
				const rawDeviceType = props.inventory[dataIndex].deviceType || null
				return rawDeviceType ? valueMapper(rawDeviceType, 'cabinetTypes') : ''
			}
		},
		{
			label: 'On Hand',
			name: 'salesQty',
			display: true,
			sort: true,
		},
		{
			label: 'Recommended Qty',
			name: 'recommendedQty',
			display: true,
			sort: true,
			customBodyRenderLite: (dataIndex: number) => {
				const recommendation = props.inventory[dataIndex]
				return <div style={{display: 'flex', alignItems: 'center'}}>
					{recommendation.recommendedQty}
					<InfoIcon 
						fontSize='small'
						style={{marginLeft: 5}} 
						onMouseMove={(e: any) => {
							setToolTipPosition({x: e.pageX, y: e.pageY})
						}}
						onMouseEnter={(e: any) => {
							setHoverData(<>
							min Par: {recommendation?.minPar || 0}<br/>
							max Par: {recommendation?.maxPar || 0}<br/>
							crl: {recommendation?.crl || 0}<br/>
							max Fill: {recommendation?.maxFill || 0}
							</>)
							setShowToolTip(true)
						}}
						onMouseLeave={(e: any) => {
							setShowToolTip(false)
						}}
					/>
				</div>
			}
		},
		{
			label: 'Default Batch',
			name: 'defaultBatch',
			display: true,
			sort: false,
			customBodyRenderLite: renderBatch
		},
		{
			label: 'Status',
			name: 'inventoryStatus',
			display: true,
			sort: true,
			customBodyRenderLite: renderStatus,
			setCellProps: () => {
				return { onMouseEnter: () => {
					setShowToolTip(false)
				}}
			}
		},
		{
			name: '',
			lable: '',
			viewColumns: false,
			display: true,
			customBodyRenderLite: (dataIndex: number) => (
				<ActionsMenu dataIndex={dataIndex} actions={getActions(dataIndex)} />
			),
			setCellProps: () => {
				return { onMouseEnter: () => {
					setShowToolTip(false)
				}}
			}
		},
	];

	const exclusionTableColumns = [
		{
			label: 'Customer Id',
			name: 'customerId',
			display: true,
			sort: true
		},
		{
			label: 'Product Id',
			name: 'productId',
			display: true,
			sort: true
		},
		{
			label: 'Exclusion',
			name: 'exclusion',
			display: true,
			sort: false
		},
	];

	const getColumnDisplay = (column: any, index: number) => {
		let display = column.display
		if (tabView === 'inventoryExceptions' && (column.name === 'salesQty' || column.name === 'recommendedQty')) {
			 display = false
		}
		return tableStatePersist ? tableStatePersist.columns[index].display : display
	}

	const buildTableColumns = (cols: any[]) => {
		if (tabView === 'inventoryExceptions') {
			cols.splice(2, 0 ,{
				label: 'Exceptions',
				name: 'warnings',
				display: true,
				sort: false,
				customBodyRenderLite: (dataIndex: number) => {
					const warnings = props.inventory[dataIndex].warnings
					return <>
						{warnings.map((warning: any) => (
							<div>{warning}</div>
						))}
					</>
				}
			})
		}
		return cols.map((column: any, index) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					change: column.change === undefined ? false : column.change,
					viewColumns: column.viewColumns === undefined ? true : column.viewColumns,
					display: getColumnDisplay(column, index),
					sort: column.sort,
					customBodyRender: column.customBodyRender ? column.customBodyRender : undefined,
					customBodyRenderLite: column.customBodyRenderLite ? column.customBodyRenderLite : undefined,
					setCellProps: column.setCellProps ? column.setCellProps : undefined,
					hint: column.hint || undefined,
				},
			};
		});
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current && tabView !== 'restockOrders') {
			props.getInventory(processTableFilter());
			setSelectedRows([])
			setSelectedTableRowIndexes([])
		} else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder]);

	const processTableFilter = () => {
		const filterValues = props.selectedFilterValues || {};
		let recommendationType = null
		if (tabView === 'recommendations') {
			recommendationType = 'recommendations'
		} else if (tabView === 'inventoryExceptions') {
			recommendationType = 'inventoryExceptions'
		}
		return {
			offset: pagination.offset,
			limit: pagination.limit,
			orderBy: sortOrder ? [sortOrder] : ['customerId ASC'],
			enforceRestockDowExclusions: true,
			inventoryStatuses: [
				"Urgent", "Replen", "Opportunity"
			],
			...(recommendationType && { recommendationType: recommendationType }),
			...(props.selectedCustomer && { customerId: props.selectedCustomer.customerId }),
			...(filterValues['PRODUCT_NAME'] && { productId: filterValues['PRODUCT_NAME'].productItemId }),
			...(filterValues['CABINET_TYPE'] && { deviceType: filterValues['CABINET_TYPE'] }),
		};
	};

	const filterRecommendations = () => {
		setPagination({ offset: 0, limit: pagination.limit, page: 0 });
	};

	const showLoading = () => !props.authUser && props.inventory.length === 0;

	const getTableData = () => {
		return tabView === 'recommendations' || tabView === 'inventoryExceptions'
		? props.inventory 
		: props.exclusions
	}

	const getTableColumns = () => {
		return tabView === 'recommendations' || tabView === 'inventoryExceptions' ? buildTableColumns(columnData) : buildTableColumns(exclusionTableColumns)
	}

	const handleRowsSelected = (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected: any[]) => {
		let selections: any[] = []
		rowsSelected.forEach((selectedIndex: number) => {
			const selectedRow = props.inventory[selectedIndex]
			const defaultBatch = Object.keys(selectedRow.defaultBatch).length === 0 ? null : selectedRow.defaultBatch
			selections.push({
				customerId: selectedRow.customerId,
				cabinetId: selectedRow.cabinets[0]?.cabinetId || '',
				productId: selectedRow.productId,
				qty: selectedRow.recommendedQty,
				inventoryStatus: selectedRow.inventoryStatus,
				warehouse: defaultBatch ? defaultBatch.warehouse : '',
				expiration: defaultBatch ? defaultBatch.expirationDate : '',
				lot: defaultBatch ? defaultBatch.lotNumber : '',
				poType: ''
			})
		})
		setSelectedRows(selections)
		setSelectedTableRowIndexes(rowsSelected)
	}

	const submitReleaseOrders = (requestBody: any[]) => {
		props.releaseOrders({
			requestBody: requestBody,
			onComplete: () => {
				props.getInventory(processTableFilter())
				setSelectedRows([])
				setSelectedTableRowIndexes([])
			}
		})
	}

	const getExportReportName = () => {
		let exportReportName = 'RNI_UIEXPORT_Recommendations';
		if (tabView === 'inventoryExceptions') {
			exportReportName = 'RNI_UIEXPORT_InventoryExceptions';
		}
		if (tabView === 'exclusions') {
			exportReportName = 'RNI_UIEXPORT_Exclusions';
		}
		return exportReportName;
	}

	return (
		<div>
			{showLoading() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid item xs={12}>
							<div style={{display: tabView === 'restockOrders' ? 'block' : 'none'}}>
								<GlobalFiltersComponentContainer pageName="RESTOCK_ORDERS" executeSearch={filterRecommendations} />
							</div>
							<div style={{display: tabView === 'recommendations' || tabView === 'inventoryExceptions' ? 'block' : 'none'}}>
								<GlobalFiltersComponentContainer
									pageName={"ORDER_MANAGEMENT"}
									executeSearch={filterRecommendations}
								/>
							</div>
							<div style={{display: tabView === 'exclusions' ? 'block' : 'none'}}>
								<GlobalFiltersComponentContainer
									pageName={"ORDER_EXCLUSIONS"}
									executeSearch={filterRecommendations}
								/>
							</div>
						</Grid>
					</div>
					
					{(tabView === 'recommendations' || tabView === 'inventoryExceptions') && <div style={{textAlign: 'right'}}>
						{selectedRows.length > 0 &&<Button
							style={{ marginTop: 10, fontSize: '10px' }}
							className={classes.tableButton}
							variant="contained"
							color="secondary"
							onClick={() => {
								setConfirmOpen(true)
							}}
						>
							Release Selected Orders
						</Button>}
						<Button
							style={{ marginTop: 10, fontSize: '10px' }}
							className={classes.tableButton}
							variant="contained"
							color="secondary"
							onClick={() => {
								setModalAction('createNewOrder')
								setModalOpen(true)
							}}
						>
							Create Order
						</Button>
					</div>}
					{tabView === 'exclusions' && <div style={{textAlign: 'right'}}>
						<Button
							style={{ marginTop: 10, fontSize: '10px' }}
							className={classes.tableButton}
							variant="contained"
							color="secondary"
							onClick={() => {
								props.syncOrderExclusions({
									...(props.selectedCustomer && { customerId: props.selectedCustomer.customerId })
								})
							}}
						>
							Sync Exclusions
						</Button>
					</div>}
						<div>
							<h1>{props.inventoryExceptionsTotalRecord}</h1>
							<AppBar position="static" color="default">
								<Tabs
									variant="scrollable"
									scrollButtons="on"
									aria-label="scrollable tabs"
									value={tabView}
									indicatorColor="secondary"
									textColor="primary"
									onChange={handleTabChange}
								>
									<Tab value="restockOrders" label="Restock Orders" />
									<Tab value="recommendations" label={<Badge badgeContent={props.recommendationsTotal} max={999} color="error">Recommendations</Badge>} />
									<Tab value="inventoryExceptions" label={<Badge badgeContent={props.inventoryExceptionsTotal} max={999} color="error">Inventory Exceptions</Badge>} />
									<Tab value="exclusions" label={<Badge badgeContent={props.exclusions.length} max={999} color="error">Exclusions</Badge>} />
								</Tabs>
							</AppBar>
							{tabView === 'restockOrders' &&
								<OrdersComponent
									pagination={pagination} 
									setPagination={setPagination}
									sortOrder={sortOrder}
									setSortOrder={setSortOrder}
									page="orderManagement"
									submitOrderNotes={props.submitOrderNotes}
								/>
							}
							{tabView !== 'restockOrders' && getTableData().length > 0 ? (
								<MUIDataTable
									data={getTableData()}
									columns={getTableColumns()}
									options={{
										rowsPerPage: pagination.limit,
										rowsPerPageOptions: dataList.PageLimitOptions.concat([200, 500]),
										onChangeRowsPerPage: (numberOfRows: number) => {
											setPagination({ ...pagination, limit: numberOfRows });
											if (numberOfRows <= 100) props.setGlobalRowsPerPage(numberOfRows);
										},
										filterType: 'dropdown',
										responsive: 'simple',
										filter: false,
										search: false,
										download: true,
										print: true,
										selectableRows: tabView === 'recommendations' || tabView === 'inventoryExceptions' ? 'multiple' : 'none',
										selectToolbarPlacement: 'none',
										serverSide: tabView === 'recommendations' || tabView === 'inventoryExceptions' ? true : false,
										page: pagination.page,
										count: tabView === 'recommendations' || tabView === 'inventoryExceptions' ? props.totalRecord : props.exclusions.length,
										fixedHeader: true, 
										tableBodyHeight: tableHeight + 'px',
										rowsSelected: selectedTableRowIndexes,
										onRowSelectionChange: handleRowsSelected,
										onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
											const filterValues = props.selectedFilterValues || {};

											const filter: any = {
												limit: 99999,
												order: 'customerId ASC',
												getRecommendedQty: true,
												inventoryStatuses: ["Urgent", "Replen", "Opportunity"],
												customerId: props.selectedCustomer?.customerId || undefined,
												deviceType: filterValues['DEVICE_TYPE'] || undefined,
												productId: filterValues['PRODUCT_ID'] || undefined
											};

											let exportReportName = getExportReportName()
											let columnsRequested = findUserSelectedColumns(columns)
											if (exportReportName !== 'RNI_UIEXPORT_Exclusions') {
												columnsRequested.push('customerNote','customerProductNote')
											}

											const params = constructEnqueueParams(
												exportReportName,
												props.authUser?.record.email,
												filter,
												{ columnsRequested: columnsRequested },
												GetUserTimezone(props.properties),
											);

											props.sendOneTimeReportRequest(params);
											return false;
										},
										onTableChange: (tableAction: any, tableState: any) => {
											console.log(tableAction);
											console.log(tableState);

											if (tableAction == 'changePage') {
												if (tabView !== 'recommendations' && tabView !== 'inventoryExceptions') {
													return
												}
												setPagination(tablePagination(tableAction, tableState, pagination));
												setSelectedRows([])
												setSelectedTableRowIndexes([])
											} else if (tableAction == 'sort') {
												setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
											} else if (tableAction == 'viewColumnsChange') {
												setTableStatePersist(tableState);
											}
										},
									}}
								/>
								) : (
									<>
										{tabView !== 'restockOrders' && <EmptyContent message="Change the filter criteria above to view order information." />}
									</>
								)}
							</div>
				</div>
			)}
			<Modal
				open={modalOpen}
				onClose={(e: any, reason: string) => {
					if (modalAction === 'createNewOrder' && reason === 'backdropClick') return;
					handleModalClose(false)
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<>
					{modalAction === 'modifyAndReleaseOrder' && <ReleaseOrderModal order={orderToModify} submitOrder={submitReleaseOrders} close={handleModalClose} />}
					{modalAction === 'createNewOrder' && <CreateOrderModal submitOrder={submitReleaseOrders} close={handleModalClose} />}
					{modalAction === 'setPar' && 
						<div style={modalStyle} className={modalClasses.paper}>
							<h3 id="simple-modal-title">Edit Par Settings</h3>
							<SetParModal
								customerId={parToEdit.customerId}
								product={parToEdit}
								productSource={parToEdit.productSource}
								closeModal={handleModalClose}
								addProduct={false}
								submit={submitPar}
								setParPayload={(par: any) => {
									// not required here
								}}
							/>
							<Grid container>
								<Grid item xs={12}>
									<FormControl className={classes.formControl}>
										<Button
											type="button"
											color="primary"
											variant="contained"
											className={classes.searchButton}
											style={{ marginTop: 12 }}
											onClick={() => {
												setSubmitPar(true);
											}}
										>
											SAVE
										</Button>
									</FormControl>
									<FormControl className={classes.formControl}>
										<Button
											type="button"
											color="default"
											variant="contained"
											className={classes.cancelBtn}
											style={{ marginTop: 12, marginLeft: 0 }}
											onClick={() => {
												handleModalClose(false);
											}}
										>
											CANCEL
										</Button>
									</FormControl>
								</Grid>
							</Grid>
						</div>
					}
				</>
			</Modal>
			<ConfirmDialog
				open={confirmOpen}
				handleClose={() => setConfirmOpen(false)}
				title="Release Selected Orders?"
				content={`Are you sure you want to release these ${selectedRows.length} selected orders?`}
				handleYes={() => {
					submitReleaseOrders(selectedRows)
					setConfirmOpen(false);
				}}
			/>
			{showToolTip &&
				<div style={{
					position: 'absolute',
					top: toolTopPosition.y,
					left: toolTopPosition.x + 20,
					borderRadius: '5px',
					height: 'auto',
					width: 'auto',
					backgroundColor: '#444',
					opacity: 0.8,
					color: '#fff',
					padding: 10
				}}>
					{hoverData}
				</div>
			}
		</div>
		
	);
};

export default OrderManagementComponent;
