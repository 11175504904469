import React from 'react';
import { connect } from 'react-redux';
import { ProductActions } from '../../redux/actions';
import { Button, Grid, FormControl, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { SelectBox } from '../../components/selectBox';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';
import Loading from '../../components/loading';
import { inputBaseClasses } from '@mui/material';

const ProductFormModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());

	const getInitInputs = () => ({
		productName: props.product?.productName || '',
		doseType: props.product?.doseType || '',
		packQty: props.product?.packQty >= 0 ?  props.product?.packQty : null,
		lumQty: props.product?.lumQty >= 0 ?  props.product?.lumQty : null,
		lumProductId: props.product?.lumProductId || null,
		lumAncillaryWeight: +props.product?.lumAncillaryWeight || null,
		relatedNdc: props.product?.relatedNdc || null,
		mbrxProductId: props.product?.mbrxProductId || null,
		mbrxProductIdExt: props.product?.mbrxProductIdExt || null,
		gtin: props.product?.gtin || null,
		weight: props.product?.weight || null,
		value: props.product?.value || null,
		active: props.product?.active || false,
		returnable: props.product?.returnable || false,
		isSerializable: props.product?.isSerializable || false,
		trackProviderTraining: props.product?.trackProviderTraining || false
	});

	const [inputs, setInputs] = React.useState<any>(getInitInputs());
	const [formErrors, setFormErrors] = React.useState<any>({});

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [submitError, setSubmitError] = React.useState<string>('');

	const handleCancel = () => {
		props.closeModal();
	};

	const handleSetInputs = (key: string, value: any) => {
		setInputs({ ...inputs, [key]: value });
		setFormErrors({ ...formErrors, [key]: null });
		setSubmitError('');
	};

	const handleSave = () => {
		const errors: any = {};
		if (!props.product && !inputs.productId) {
			errors.productId = 'A product id is required';
		}
		if (props.product && !inputs.productName) {
			errors.productName = 'A product name is required';
		}
		if ( isNaN(parseInt(inputs.packQty)) ) {
			errors.packQty = 'Pack Qty is required';
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setIsLoading(true);

		const onSuccess = () => {
			setIsLoading(false);
			props.getProducts();
			props.closeModal();
		};

		const onError = () => {
			setIsLoading(false);
			setSubmitError('Failed to save product');
		};

		const requestBody = props.product ? getEditRequestBody() : getAddRequestBody();

		delete requestBody.createdAt;

		if (!requestBody.lumQty && requestBody.lumQty !== 0) {
			delete requestBody.lumQty;
		}
		if (!requestBody.mbrxProductId) {
			delete requestBody.mbrxProductId;
		}
		if (!requestBody.mbrxProductIdExt) {
			delete requestBody.mbrxProductIdExt;
		}

		const payload = {
			requestBody: requestBody,
			success: onSuccess,
			error: onError,
		};

		const submitFn = props.product ? props.updateProduct : props.createProduct;

		submitFn(payload);
	};

	const getAddRequestBody = () => ({
		product: {
			productId: inputs.productId,
			doseType: inputs.doseType ?? 'PACK',
			packQty: +inputs.packQty,
			lumQty: +inputs.lumQty,
			relatedNdc: inputs.relatedNdc,
			// productGroup: "",
			// packUom: "",
			lumProductId: inputs.lumProductId,
			lumAncillaryWeight: +inputs.lumAncillaryWeight ?? 0.0,
			mbrxProductId: inputs.mbrxProductId,
			mbrxProductIdExt: inputs.mbrxProductIdExt,
			gtin: inputs.gtin,
			weight: +inputs.weight,
			value: +inputs.value,
			active: inputs.active ? 1 : 0,
			returnable: inputs.returnable ? 1 : 0,
			isSerializable: inputs.isSerializable ? 1 : 0,
			trackProviderTraining: inputs.trackProviderTraining ? 1 : 0,
			source: 'FFF',
		},
	});

	const getEditRequestBody = () => ({
		...props.product,
		...{
			productName: inputs.productName,
			doseType: inputs.doseType ?? 'PACK',
			packQty: +inputs.packQty,
			lumQty: +inputs.lumQty,
			relatedNdc: inputs.relatedNdc,
			// productGroup: "",
			// packUom: "",
			lumProductId: inputs.lumProductId,
			lumAncillaryWeight: +inputs.lumAncillaryWeight ?? 0.0,
			mbrxProductId: inputs.mbrxProductId,
			mbrxProductIdExt: inputs.mbrxProductIdExt,
			gtin: inputs.gtin,
			weight: +inputs.weight,
			value: +inputs.value,
			active: inputs.active ? 1 : 0,
			returnable: inputs.returnable ? 1 : 0,
			isSerializable: inputs.isSerializable ? 1 : 0,
			trackProviderTraining: inputs.trackProviderTraining ? 1 : 0
		},
	});

	return (
		<div style={modalStyle} className={modalClasses.paper}>
			<div>
				<h3 id="simple-modal-title">{props.product ? 'Edit Product' : 'Add New Product'}</h3>
				<Grid container>
					<Grid item xs={12}>
						{!props.product && (
							<FormControl className={classes.formControl}>
								<TextField
									label="Product Id"
									variant="outlined"
									value={inputs.productId}
									error={formErrors.productId ? true : false}
									helperText={formErrors.productId ? formErrors.productId : ''}
									onChange={event => {
										handleSetInputs('productId', event.target.value);
									}}
									required
								/>
							</FormControl>
						)}
						{props.product && (
							<FormControl className={classes.formControl}>
								<TextField
									label="Product name"
									variant="outlined"
									value={inputs.productName}
									error={formErrors.productName ? true : false}
									helperText={formErrors.productName ? formErrors.productName : ''}
									onChange={event => {
										handleSetInputs('productName', event.target.value);
									}}
									required
								/>
							</FormControl>
						)}
						<FormControl className={classes.formControl}>
							<SelectBox
								style={{ width: 210 }}
								inputLabel={'Dose Type'}
								emptyItemLabel={'Select Dose Type'}
								listItems={[
									{ label: 'PACK', value: 'PACK' },
									{ label: 'APPLICATOR', value: 'APPLICATOR' },
									{ label: 'MULTI DOSE VIAL', value: 'MULTI DOSE VIAL' },
									{ label: 'SYRINGE', value: 'SYRINGE' },
									{ label: 'VIAL', value: 'VIAL' },
									{ label: 'VIAL DILUENT', value: 'VIAL DILUENT' },
								]}
								selected={inputs.doseType}
								error={formErrors.doseType ? true : false}
								errorText={formErrors.doseType || ''}
								onChangeItem={(value: any) => {
									handleSetInputs('doseType', value);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Pack Qty"
								variant="outlined"
								type="number"
								value={inputs.packQty}
								error={formErrors.packQty ? true : false}
								helperText={formErrors.packQty ? formErrors.packQty : ''}
								onChange={event => {
									handleSetInputs('packQty', event.target.value);
								}}
								required
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Lum Qty (Units Per Box)"
								variant="outlined"
								type="number"
								value={inputs.lumQty}
								error={formErrors.lumQty ? true : false}
								helperText={formErrors.lumQty ? formErrors.lumQty : ''}
								onChange={event => {
									handleSetInputs('lumQty', event.target.value);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Mbrx Product Id"
								variant="outlined"
								value={inputs.mbrxProductId}
								error={formErrors.mbrxProductId ? true : false}
								helperText={formErrors.mbrxProductId ? formErrors.mbrxProductId : ''}
								onChange={event => {
									handleSetInputs('mbrxProductId', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Mbrx Product Id Ext"
								variant="outlined"
								value={inputs.mbrxProductIdExt}
								error={formErrors.mbrxProductIdExt ? true : false}
								helperText={formErrors.mbrxProductIdExt ? formErrors.mbrxProductIdExt : ''}
								onChange={event => {
									handleSetInputs('mbrxProductIdExt', event.target.value);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Cost Value"
								variant="outlined"
								type="number"
								value={inputs.value}
								error={formErrors.value ? true : false}
								helperText={formErrors.value ? formErrors.value : ''}
								onChange={event => {
									handleSetInputs('value', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="GTIN"
								variant="outlined"
								value={inputs.gtin || ''}
								error={formErrors.gtin ? true : false}
								helperText={formErrors.gtin ? formErrors.gtin : ''}
								onChange={event => {
									handleSetInputs('gtin', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Weight (grams)"
								variant="outlined"
								type="number"
								value={inputs.weight}
								error={formErrors.weight ? true : false}
								helperText={formErrors.weight ? formErrors.weight : ''}
								onChange={event => {
									handleSetInputs('weight', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Related NDC"
								variant="outlined"
								value={inputs.relatedNdc}
								error={formErrors.relatedNdc ? true : false}
								helperText={formErrors.relatedNdc ? formErrors.relatedNdc : ''}
								onChange={event => {
									handleSetInputs('relatedNdc', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Lum Product Id"
								variant="outlined"
								value={inputs.lumProductId}
								error={formErrors.lumProductId ? true : false}
								helperText={formErrors.lumProductId ? formErrors.lumProductId : ''}
								onChange={event => {
									handleSetInputs('lumProductId', event.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Lum Ancillary Weight (grams)"
								variant="outlined"
								type="number"
								value={inputs.lumAncillaryWeight}
								error={formErrors.lumAncillaryWeight ? true : false}
								helperText={formErrors.lumAncillaryWeight ? formErrors.lumAncillaryWeight : ''}
								onChange={event => {
									handleSetInputs('lumAncillaryWeight', event.target.value);
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch
										checked={inputs.active ? true : false}
										onChange={() => {
											const currentValue = inputs.active ? true : false;
											handleSetInputs('active', !currentValue);
										}}
										name={'active'}
									/>
								}
								label={'Is Active'}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch
										checked={inputs.returnable ? true : false}
										onChange={() => {
											const currentValue = inputs.returnable ? true : false;
											handleSetInputs('returnable', !currentValue);
										}}
										name={'returnable'}
									/>
								}
								label={'Is Returnable'}
							/>
						</FormControl>
							<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch
										checked={inputs.trackProviderTraining ? true : false}
										onChange={() => {
											const currentValue = inputs.trackProviderTraining ? true : false;
											handleSetInputs('trackProviderTraining', !currentValue);
										}}
										name={'trackProviderTraining'}
									/>
								}
								label={'Track Provider Training'}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch
										checked={inputs.isSerializable ? true : false}
										onChange={() => {
											const currentValue = inputs.isSerializable ? true : false;
											handleSetInputs('isSerializable', !currentValue);
										}}
										name={'isSerializable'}
									/>
								}
								label={'Is Serializable'}
							/>
						</FormControl>
					</Grid>
					{submitError && <p className={classes.errorMsg}>{submitError}</p>}
					{isLoading ? (
						<Loading message="" />
					) : (
						<Grid item xs={12}>
							<FormControl className={classes.formControl}>
								<Button
									onClick={handleSave}
									type="button"
									style={{ marginTop: 12 }}
									className={classes.searchButton}
									variant="contained"
									color="primary"
								>
									SAVE
								</Button>
							</FormControl>
							<FormControl className={classes.formControl}>
								<Button
									onClick={handleCancel}
									type="button"
									style={{ marginTop: 12, marginLeft: 0 }}
									className={classes.cancelBtn}
									variant="contained"
									color="default"
								>
									CANCEL
								</Button>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	createProduct: (payload: any) => dispatch(ProductActions.saveNewProduct(payload)),
	updateProduct: (payload: any) => dispatch(ProductActions.updateProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormModal);
