import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import InvoiceHistoryComponent from '../invoice/invoice-history.component';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';

const CreditCardPayableInvoices = () => {
	const styles = DashboardStyles();
	const firstRender = useRef(true); 
	const dispatch = useDispatch();
	useEffect(() => {
		firstRender.current = false;
	}, [dispatch]);

	return ( 
		<BasePage title={Constants.PAGE_TITLE.CREDIT_CARD_PAYABLE_INVOICES}>
			<div className={styles.appBarSpacer}></div>
			<Container maxWidth={false} className={styles.container}>
				<InvoiceHistoryComponent isCCPaymentMode={true} />
			</Container>
		</BasePage>
	);
};

export default CreditCardPayableInvoices;